import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ordersUrl } from "../../const";

function ShippingAddress({ shippingAddress, orderId, orders,userid }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" className="p-1 f-right mx-1" onClick={handleShow}>
        <i className="bi bi-eye-fill"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            OrderID <b className="text-success">{orderId}</b> Shipping Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                <b>
                  {shippingAddress?.firstName} {shippingAddress?.lastName || ""}
                </b>
              </p>
              <p>
                {shippingAddress?.addressline1 || ""}{" "}
                {shippingAddress?.addressline2 || ""}
              </p>
              <p>
                {shippingAddress?.city || ""} - {shippingAddress?.zipcode || ""}
              </p>
              <p>{shippingAddress?.state || ""}</p>
              <h4 className="text-dark mb-3" onClick={() => window.open(`${ordersUrl}?userid=${userid}`, '_blank')}>Total Orders : {orders}</h4>
              <Button variant="success">
                <a href={`tel:${shippingAddress?.phone}`} className="text-white" style={{ textDecoration: 'none'}}>
                    <i className="bi bi-telephone-fill"></i> {shippingAddress?.phone}
                </a>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShippingAddress;
