import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {getUsers, resetUsersList} from "../../reducers/commonReducer";
import { useLocation } from "react-router-dom";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.string()
    .required('Phone number is required')
    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
  email: yup.string().required(),
  status: yup.boolean().required(),
  role: yup.string().required(),
  city:yup.string().required(),
  state: yup.string().required(),
  zipcode: yup.string().required(),
  addressline1: yup.string().required(),
  id: yup.string(),
  password: yup.string().when('id', {
    is: (id) => !id, 
    then: yup.string().required('Password is required when id is undefined'),
    otherwise: yup.string() 
  })
});
function UserAction(props) {
  const dispatch = useDispatch();
  const {
    title,
    firstName,
    lastName,
    phone,
    secondPhone,
    password,
    email,
    role,
    category,
    city,
    state,
    zipcode, status,
    addressline1,
    addressline2,
    id, api, btn
  } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userType = useLocation().pathname.split("/").filter(Boolean).pop();
  const { getAllStatesList, getCategoriesData, getRolesData } = useSelector(
    (state) => state.commonReducer
  );

  return (
    <>
      <Button variant={btn ? btn : "primary"} onClick={handleShow}>
        {title ? title : <i className="bi bi-pencil-square"></i>}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} User</Modal.Title>
        </Modal.Header>
        {console.log("city", city)}
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            const apiResp = await dispatch(api(values));
            if(apiResp?.payload?.status){
              dispatch(resetUsersList());
              dispatch(getUsers({
                slug:(userType === "vendors" && "vender") ||
                (userType === "users" && "customer") ||
                (userType === "checker" && "checker") ||
                (userType === "manager" && "manager"),
              }));
              handleClose();
            }
          }}
          initialValues={{
            firstName,
            lastName,
            phone,
            secondPhone,
            password,
            email,
            role,
            category : !!category && category?.map((cat) => cat?.id),
            city,
            state,
            zipcode,
            addressline1,
            addressline2,
            status,
            id,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Second Phone</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Second Phone"
                      name="secondPhone"
                      value={values.secondPhone}
                      onChange={handleChange}
                      isInvalid={!!errors.secondPhone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.secondPhone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("status", e.target.value);
                      }}
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select status</option>
                      <option value={true}>Active</option>
                      <option value={false}>InActive</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="role"
                      className="form-control"
                      value={values.role}
                      isInvalid={!!errors.role}
                    >
                      <option value="">Select Role</option>
                      {
                        !!getRolesData && getRolesData?.map((role, index) =>  <option key={index} value={role?.id}>{role?.name}</option>)
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.role}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {values?.role !== "66974c944b79b025983790df" && <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      multiple={true}
                      onChange={handleChange}
                      name="category"
                      className="form-control"
                      value={values.category}
                      isInvalid={!!errors.category}
                    >
                      <option value="">Select Category</option>
                      {
                        !!getCategoriesData && getCategoriesData?.map((cat, index) =>  <option key={index} value={cat?.id}>{cat?.name}</option>)
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.category}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
              </Row>
              <Row className="mb-3">
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Pin Code</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Pin code"
                      name="zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                      isInvalid={!!errors.zipcode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zipcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="state"
                      className="form-control"
                      value={values.state}
                      isInvalid={!!errors.state}
                    >
                      <option value="">Select state</option>
                      {
                        !!getAllStatesList && getAllStatesList?.map((state, index) => <option value={state} key={index}>{state}</option>)
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
              <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Addressline1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Addressline1"
                      name="addressline1"
                      value={values.addressline1}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Addressline2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Addressline2"
                      name="addressline2"
                      value={values.addressline2}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {(values?.role !== "66974c944b79b025983790df") && <Col md={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>}
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UserAction;
