import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import dummy from "../../images/dummy.jpeg";
import { getCountProduct } from "../../reducers/frontEndReducer";
import { calculateImageSize, handleContextMenu, productRating } from "../../const";
import CustomizeProduct from "./CustomizeProduct";

const OnSaleProducts = ({ productsDetails, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useRef(window.innerWidth);
  const productImageRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current, 4);
    dispatch(
      getCountProduct({
        counter: 8,
        width: cwidth,
        height: cheight
      })
    );
  }, [width.current]);
  useEffect(() => {
    if (productImageRef.current) {
      const width = productImageRef.current.offsetWidth;
      const height = productImageRef.current.offsetHeight;
      setDimensions({ width, height });
    }
  }, []);

  return (
    <>
      {!!productsDetails?.list && productsDetails?.list?.length > 0 && (
        <section className="onsale__area pt-15 pb-40">
          <div className="row">
            <div className="col-xl-12">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 style={{ fontWeight: 'bold', color: '#333' }}>
                  <span>{title}</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            {productsDetails?.list.map((data, index) => {
              return (
                <Col md={3} sm={6} xs={6} className="p-0" key={index}>
                  <div className="product-grid card " style={{borderRadius:'16px'}}>
                    <div className="product-image" ref={productImageRef} style={{borderRadius:'20px'}}>
                      <div className="card-img-actions " >
                        <Link
                          to={`/product/${data?.slug}?productStatus=${data?.status}`}
                          className="text-default mb-2"
                          aria-label={`View details about ${data?.name}`}
                          title={data?.name}
                        >
                           {(data?.productType === "customize") ? <CustomizeProduct coloums={4} customizeDetails={data?.customizeDetails}/> : <img
                          src={
                            data?.images
                            ? data?.images[data?.defaultImage]
                              : dummy
                          }
                          loading="lazy"
                          className="card-img-top"
                          alt={data?.name}
                          onContextMenu={handleContextMenu}
                          style={{ transition: 'transform 0.3s ease' }}
                        />}
                      </Link> 
                      {!!data?.sale && (
                        <span className="discount-lable"
                         >
                          -{((100 * (data?.mrp - data?.sale)) / data?.mrp).toFixed(2)}%
                        </span>
                      )}
                      <ul
                        className="list-unstyled d-flex justify-content-center d-none"
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          zIndex: 1,
                        }}
                      >
                        <li>
                          <NavLink to={`/product/${data?.slug}?productStatus=${data?.status}`}>
                            <i className="bi bi-search" style={{ color: '#333' }}></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    
                    <div className="card-body text-center">
                      <Link to={`/product/${data?.slug}?productStatus=${data?.status}`} className="text-default mb-2" aria-label={`View details about ${data?.name}`}>
                        <b className="product-name">{data?.name.length > 58 ? data?.name.slice(0, 58) + "..." : data?.name}</b>
                      </Link>
                      <ul>{data?.avgRating > 0 ? productRating(Math.round(data?.avgRating)) : null}</ul>
                      <p className="mb-0" style={{ color: '#10653D', fontWeight: 'bold' }}>
                        <span style={{ textDecoration: 'line-through', color: '#AD1A2C' }}>
                          Rs.{data?.mrp}
                        </span>{" "}
                        Rs.{data?.sale}
                      </p>
                      
                      <button
                        className="btn theme-btn add-to-cart rounded mt-2"
                        // style={{
                        //   borderRadius: '10px',
                        //   width: '78%',
                        //   color:'white',
                        //   fontSize:'12px'
                        // }}
                        onClick={() => navigate(`/product/${data?.slug}?productStatus=${data?.status}`)}
                      >
                        {data?.productType === "customize" ? `Customize & Add To Cart` : `Add to Cart`}
                      </button>
                    </div>
                  </div>
                  </div>
                  
                </Col>
                
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default OnSaleProducts;
