import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Table, Container, Button } from "react-bootstrap";
import printer from "../../images/printer.png";
import { useDispatch, useSelector } from "react-redux";
import {
  adminOrders,
  manualOrderShip,
  nextPrevOrderId,
  reShipping,
  shippingCancel,
  singleOrder,
  updateOrderItem,
} from "../../reducers/orderDetailsReducer";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {
  addProductUrl,
  adminUserAddressUrl,
  handleContextMenu,
  orderDetailsUrl,
  ordersUrl,
  pendingOrdersUrl,
  product,
} from "../../const";
import OrderImgPopUp from "../../ComponentsWeb/Main/OrderImgPopUp";
import UpdateAddress from "../../ComponentsWeb/Main/user/UpdateAddress";
import OrderComment from "./OrderComment";
import Timeline from "./Timeline";
import AWBAction from "./AWBAction";
import OrderAmountUpdate from "./OrderAmountUpdate";
import ProductSelection from "./ProductSelection";
import { allProductListCatWise } from "../../reducers/commonReducer";

function AdminOrderDetail(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const pendingOrder = queryParameters.get("pending");
  useEffect(() => {
    if (orderId) {
      dispatch(singleOrder({ id: orderId }));
    }
    // dispatch(allProductListCatWise());
  }, [orderId, dispatch]);
  const { singleOrderData, orderCount } = useSelector(
    (state) => state.orderDetailReducer
  );
  // 0 pending,1 cancel, 2 processing, 3 verify 4 In_Transit , 5 shipping, 6 completed
  let orderStatus;
  if (singleOrderData?.status === 0) {
    orderStatus = "Pending";
  } else if (singleOrderData?.status === 1) {
    orderStatus = "Cancel";
  } else if (singleOrderData?.status === 2) {
    orderStatus = "Processing";
  } else if (singleOrderData?.status === 3) {
    orderStatus = "Verify";
  } else if (singleOrderData?.status === 4) {
    orderStatus = "In_Transit";
  } else if (singleOrderData?.status === 5) {
    orderStatus = "Shipping";
  } else if (singleOrderData?.status === 6) {
    orderStatus = "Completed";
  } else {
    orderStatus = null;
  }
  const handleCopy = () => {
    const textToCopy = singleOrderData?.successid
      ? singleOrderData.successid
      : singleOrderData.orderid;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 300); // Reset after animation
    });
  };
  // let mainPriceCount = 0;
  let salePriceCount = 0;
  return (
    <>
      <Container className="mb-1">
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <div className="d-flex">
                  <Link
                    to={pendingOrder === "true" ? pendingOrdersUrl : localStorage.getItem(`orderListUrl`)}
                    //onClick={() => dispatch(adminOrders())}
                  >
                    <h5>
                      <i className="bi bi-arrow-left"></i>{" "}
                      <b className="text-black">
                        {singleOrderData?.successid
                          ? singleOrderData?.successid
                          : singleOrderData?.orderid}
                      </b>
                    </h5>
                  </Link>
                  <i
                    onClick={handleCopy}
                    className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                      copied ? "copy-zoom" : ""
                    }`}
                  ></i>
                </div>
              </Col>
              {localStorage.getItem("slug") !== "checker" ? (
                <Col md={6}>
                  {/* <Button variant="warning" className="f-right mx-3">
                    <i className="bi bi-clipboard"></i> Duplicate
                  </Button> */}
                  <img
                    className="w-auto f-right"
                    onClick={() => window.print()}
                    src={printer}
                  />
                </Col>
              ) : null}
            </Row>
            <p className="mb-0">
              {moment(singleOrderData?.successDate ? singleOrderData?.successDate : singleOrderData?.createdAt).format(
                "MMMM Do YYYY, h:mm a"
              )}{" "}
              from Online Store
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            {/* order details */}
            <div className="border rounded p-2">
              <Button variant="success" className="px-2 py-1">
                <i className="bi bi-truck"></i> {orderStatus} (
                {singleOrderData?.totalquantity})
              </Button>
              {/* reshipping and shipping cancel */}
              {((singleOrderData?.status !== 6) && (singleOrderData?.otherWayBills?.length || singleOrderData?.waybillno?.length)) ? (singleOrderData?.shippingCancel ? <Button variant="success"
              onClick={async() => {
                const confirmResp = window.confirm("Are you sure this order Re-Shipping?")
                if(confirmResp){
                  const apiResp = await dispatch(reShipping({
                    orderid: orderId
                  }))
                  if(apiResp?.payload?.status){
                   await dispatch(singleOrder({ id: orderId }));
                  }
                }
              }}
              className="px-2 py-1 f-right">
               Re-Shipping
              </Button> : <Button variant="danger"
              onClick={async() => {
                const confirmResp = window.confirm("Are you sure this order shipping cancel?")
                if(confirmResp){
                  const apiResp = await dispatch(shippingCancel({
                    id: orderId
                  }))
                  if(apiResp?.payload?.status){
                    await dispatch(singleOrder({ id: orderId }));
                  }
                }
              }}
              className="px-2 py-1 f-right">
              <i className="bi bi-x-lg"></i> Shipping Cancel
              </Button>) : null}
            {/* end reshipping and shipping cancel */}
              <div className="border rounded mt-2 p-2">
                <p className="mb-0">Fulfilled</p>
                <p className="mb-0">
                  {moment(singleOrderData?.successDate ? singleOrderData?.successDate : singleOrderData?.createdAt).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </p>
                {singleOrderData?.items &&
                  singleOrderData?.items?.map((item, index) => {
                    // mainPriceCount += item?.productId?.sale * item?.quantity
                    salePriceCount += item?.sale * item?.quantity;
                    //BUY MORE, SAVE MORE
                    let qtyWiseDiscountPrice = 0;
                    // let
                    if (item?.productId?.price) {
                      let pricediscounts = Object.keys(item?.productId?.price);
                      pricediscounts = pricediscounts.sort((a, b) => b - a);
                      for (let singlepriceDiscount of pricediscounts) {
                        if (item?.quantity >= parseInt(singlepriceDiscount)) {
                          qtyWiseDiscountPrice = parseInt(
                            item?.productId?.price[
                              parseInt(singlepriceDiscount)
                            ]
                          );
                          break;
                        }
                      }
                    }
                    return (
                      <Fragment key={index}>
                        <hr />
                        {qtyWiseDiscountPrice > 0 && (
                          <Row>
                            <Col>
                              <h6 className="text-success">
                                <b>BUY MORE, SAVE MORE</b>
                              </h6>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col md={1}>
                            {!singleOrderData?.orderCreatedByAdmin && (
                              <img
                                src={
                                  item?.mergeimg
                                    ? item?.mergeimg
                                    : item?.productId?.images?.[0]
                                }
                                alt={item?.productId?.name}
                                loading="lazy"
                                onContextMenu={handleContextMenu}
                              />
                            )}
                            <Button
                              className="f-18 mt-1"
                              variant={
                                item?.verifyStatus ? "success" : "warning"
                              }
                            >
                              {item?.verifyStatus ? (
                                <i className="bi bi-check2-all"></i>
                              ) : (
                                <i className="bi bi-disc-fill"></i>
                              )}
                            </Button>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0 text-black">
                              <b>{item?.productId?.name}</b>
                            </p>
                            <p className="mb-0 text-black">
                              <b>SKU</b>: {item?.productId?.sku}
                            </p>
                            {item?.itemid && (
                              <p className="mb-0 text-success f-18">
                                <b>Item ID: {item?.itemid}</b>
                              </p>
                            )}
                            {!!item?.attribute?.name && (
                              <p className="mb-0 text-black">
                                <b>- Attribute</b>: {item?.attribute?.name}
                              </p>
                            )}
                            {item?.userimg?.length > 0 && (
                              <p className="text-black mb-0">
                                <b>- You Image:</b>
                                {item?.userimg?.map((userimages, imgIndex) => (
                                  <Link
                                    key={imgIndex}
                                    to={userimages?.imageUrl}
                                    target="_blank"
                                  >
                                    <Button className="px-1 py-0 m-1 theme-btn">
                                      <i className="bi bi-eye"></i>
                                    </Button>
                                  </Link>
                                ))}
                              </p>
                            )}
                            {item?.inputFields?.length > 0 && (
                              <>
                                <p className="text-black mb-0">
                                  <b>- Input Fields:</b>
                                </p>
                                {item?.inputFields
                                  ?.reduce((uniqueInputs, currentInput) => {
                                    if (
                                      !uniqueInputs.some(
                                        (input) =>
                                          input?.slug === currentInput?.slug
                                      )
                                    ) {
                                      uniqueInputs.push(currentInput);
                                    }
                                    return uniqueInputs;
                                  }, [])
                                  .map((inputs, inputIndex) => {
                                    return (
                                      <Fragment key={inputIndex}>
                                        <p className="mb-0">
                                          <strong>-{inputs?.name}</strong>:{" "}
                                          <b className="text-black">
                                            {inputs?.value}
                                          </b>
                                        </p>
                                      </Fragment>
                                    );
                                  })}
                                {/* {item?.inputFields?.map((inputs, inputIndex) => {
                              return (
                                <Fragment key={inputIndex}>
                                  <p className="mb-0">
                                    <strong>-{inputs?.name}</strong>:{" "}
                                    <b className="text-black">
                                      {inputs?.value}
                                    </b>
                                  </p>
                                </Fragment>
                              );
                            })} */}
                              </>
                            )}
                            {localStorage.getItem("slug") === "admin" ? (
                              <p className="text-black mb-0">
                                <b>- ProductUrl:</b>
                                <Link
                                  to={`${addProductUrl}?slug=${item?.productId?.slug}`}
                                >
                                  <Button className="px-1 py-0 m-1 theme-btn">
                                    <i className="bi bi-pen-fill"></i>
                                  </Button>
                                </Link>
                                <Link
                                  to={`${product}/${item?.productId?.slug}`}
                                  target="_blank"
                                >
                                  <Button className="px-1 py-0 m-1 theme-btn">
                                    <i className="bi bi-eye"></i>
                                  </Button>
                                </Link>
                              </p>
                            ) : null}
                            <p className="text-black mb-0">
                              <b>- Image:</b>
                              <OrderImgPopUp
                                title={"Check You Product"}
                                image={
                                  item?.productId?.productType === "normal"
                                    ? item?.productId?.images?.[0]
                                    : item?.mergeimg
                                }
                                btn={true}
                              />
                              {localStorage.getItem("slug") === "admin" ? (
                                <Link
                                  to={
                                    item?.productId?.productType === "normal"
                                      ? item?.productId?.images?.[0]
                                      : item?.mergeimg
                                  }
                                  target="_blank"
                                >
                                  <Button
                                    className="px-2 py-1 mx-1"
                                    variant="warning"
                                  >
                                    <i className="bi bi-download"></i>
                                  </Button>
                                </Link>
                              ) : null}
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className="mb-0 text-black">
                              <b>
                                ₹ {item?.sale} <i className="bi bi-x"></i>{" "}
                                {item?.quantity}
                              </b>
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className="mb-0 text-black">
                              <b>₹ {item?.sale * item?.quantity}</b>
                            </p>
                          </Col>
                          <Col md={1}>
                            {singleOrderData?.orderCreatedByAdmin ? (
                              <ProductSelection
                                id={singleOrderData?.id}
                                api={updateOrderItem}
                                products={singleOrderData?.items}
                                paymenttype={singleOrderData?.paymenttype}
                              />
                            ) : (
                              <Link
                                target="_blank"
                                to={`/admin/product/${item?.productId?.slug}?orderid=${singleOrderData?.id}&itemIndex=${index}&productType=true`}
                              >
                                <Button>
                                  <i className="bi bi-pen-fill"></i>
                                </Button>
                              </Link>
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
            {/* Payment details */}
            {localStorage.getItem("slug") === "admin" ? (
              <div className="border rounded p-2 mt-4">
                <Button variant="secondary" className="px-2 py-1">
                  <i className="bi bi-bookmark-check-fill"></i>{" "}
                  {singleOrderData?.paymenttype}
                </Button>
                <OrderAmountUpdate
                  shippingCost={singleOrderData?.shippingCost}
                  discountType={singleOrderData?.adminDiscount?.discountType}
                  discount={singleOrderData?.adminDiscount?.discount}
                  comment={singleOrderData?.adminDiscount?.comment}
                  codChargesAmount={singleOrderData?.codChargesAmount}
                  api={updateOrderItem}
                  id={singleOrderData?.id}
                  orderId={orderId}
                />
                <div className="border rounded mt-2 p-2">
                  <Row>
                    <Col md={3}>Subtotal</Col>
                    <Col md={6}>
                      <p>{singleOrderData?.totalquantity} items</p>
                    </Col>
                    <Col md={3}>
                      <p>₹ {salePriceCount}</p>
                    </Col>
                  </Row>
                  {singleOrderData?.adminDiscount?.totalDiscount ? (
                    <Row>
                      <Col md={3}>Discount</Col>
                      <Col md={6}>
                        {singleOrderData?.adminDiscount?.comment}
                      </Col>
                      <Col md={3}>
                        <p>
                          ₹{" "}
                          {singleOrderData?.adminDiscount?.totalDiscount?.toFixed(
                            0
                          )}
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                  {singleOrderData?.codChargesAmount ? (
                    <Row>
                      <Col md={3}>COD Charges</Col>
                      <Col md={6}></Col>
                      <Col md={3}>
                        <p>₹ {singleOrderData?.codChargesAmount}</p>
                      </Col>
                    </Row>
                  ) : null}
                  {singleOrderData?.shippingCost ? (
                    <Row>
                      <Col md={3}>Shipping Cost</Col>
                      <Col md={6}></Col>
                      <Col md={3}>
                        <p>₹ {singleOrderData?.shippingCost}</p>
                      </Col>
                    </Row>
                  ) : null}
                  {singleOrderData?.coupondiscount ? (
                    <Row>
                      <Col md={3}>Coupon Discount</Col>
                      <Col md={6}>
                        <p>Code :- {singleOrderData?.offer?.name}</p>
                      </Col>
                      <Col md={3}>
                        <p className="text-success">
                          ₹ {singleOrderData?.coupondiscount}
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                  {singleOrderData?.qtydiscount ? (
                    <Row>
                      <Col md={3}>Qty Discount</Col>
                      <Col md={6}>
                        <p>Name:- {singleOrderData?.offer?.name}</p>
                      </Col>
                      <Col md={3}>
                        <p className="text-success">
                          ₹ {singleOrderData?.qtydiscount}
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col md={3}>
                      <b className="text-black">Total</b>
                    </Col>
                    <Col md={6}></Col>
                    <Col md={3}>
                      <p className="text-black">
                        <b>
                          ₹{" "}
                          {(
                            singleOrderData?.codChargesAmount +
                            singleOrderData?.totalPaybleAmount +
                            singleOrderData?.shippingCost
                          ).toFixed(0)}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={3}>
                      <b className="text-black">Paid</b>
                    </Col>
                    <Col md={6}></Col>
                    <Col md={3}>
                      <p className="text-black">
                        <b>
                          ₹{" "}
                          {(
                            singleOrderData?.codChargesAmount +
                            singleOrderData?.totalPaybleAmount +
                            singleOrderData?.shippingCost
                          ).toFixed(0)}
                        </b>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : null}
            <OrderComment />
            <Timeline
              comment={singleOrderData?.comments}
              logs={singleOrderData?.logs}
              user={singleOrderData?.user}
              shiplog={singleOrderData?.shiplog}
            />
          </Col>
          {localStorage.getItem("slug") === "admin" ? (
            <Col md={4}>
              {/* customer Details */}
              <div className="border rounded p-2 mb-2">
                <Row>
                  <Col md={6}>
                    <h5 className="text-black">WayBill Number</h5>
                  </Col>
                  <Col md={6}>
                    {singleOrderData?.status >= 2 && (
                      <AWBAction
                        title={"Update"}
                        id={orderId}
                        awb={
                          singleOrderData?.otherWayBills
                            ? singleOrderData?.otherWayBills
                            : singleOrderData?.waybillno
                        }
                        api={updateOrderItem}
                      />
                    )}
                    {(singleOrderData?.status === 2 ||
                      singleOrderData?.status === 3) && (
                      <Button
                        variant="warning"
                        className="px-2 py-0 m-1 f-right"
                        onClick={async () => {
                          const userConfirm = window.confirm(
                            "Are you sure for ship this order?"
                          );
                          if (userConfirm) {
                            const apiRsp = await dispatch(
                              manualOrderShip({
                                orderid: orderId,
                              })
                            );
                            if (apiRsp?.payload?.status) {
                              dispatch(singleOrder({ id: orderId }));
                            }
                          }
                        }}
                      >
                        Ship
                      </Button>
                    )}
                  </Col>
                </Row>
                <hr />
                {/* {console.log("Sgweg", singleOrderData?.otherWayBills?.length)} */}
                {singleOrderData?.otherWayBills?.length > 0 ? (
                  singleOrderData?.otherWayBills?.map((bill, billIndex) => {
                    return (
                      <Row key={billIndex}>
                        <Col md={5}>
                          <b className="text-success">{bill?.id}</b>
                        </Col>
                        <Col md={4}>
                          <b>{bill?.waybillno}</b>
                        </Col>
                        <Col md={3}>
                          <b>{typeof bill?.status?.Status === "string" ? bill?.status?.Status : bill?.status?.Status?.Status}</b>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Row>
                    <Col md={12}>{singleOrderData?.waybillno}</Col>
                  </Row>
                )}
              </div>
              <div className="border rounded p-2">
                <h6 className="text-black mb-0">
                  <b>Customer</b>
                </h6>
                <p className="mb-0">
                  <strong>{`${
                    singleOrderData?.user?.address?.[
                      singleOrderData?.user?.defaultAddress
                    ]?.firstName
                  } ${
                    singleOrderData?.user?.address?.[
                      singleOrderData?.user?.defaultAddress
                    ]?.lastName || ""
                  }`}</strong>
                </p>
                <div className="d-flex justify-content-between mt-2">
                  {/* {orderCount ? ( */}
                    <Link
                      to={`${ordersUrl}?userid=${singleOrderData?.user?.id}`}
                      aria-label="Order Type"
                    >
                      {/* <p>
                      <b>{orderCount} Orders</b>
                    </p> */}
                      <p className="mb-0">
                        COD Orders: {singleOrderData?.user?.orderCounter?.cod}
                      </p>
                      <p className="mb-0">
                        Prepaid Orders:{" "}
                        {singleOrderData?.user?.orderCounter?.prepaid}
                      </p>
                      <hr/>
                      <p>
                        <b>Total Orders:{" "}
                        {singleOrderData?.user?.orderCounter?.total}</b>
                      </p>
                    </Link>
                  {/* ) : null} */}
                  <Link
                    variant="warning"
                    to={() =>
                      navigate(
                        `${adminUserAddressUrl}?userid=${singleOrderData?.user?.id}`
                      )
                    }
                  >
                    <p>
                      <b>
                        More Address <i className="bi bi-arrow-right"></i>
                      </b>
                    </p>
                  </Link>
                </div>
                <p className="mb-0">
                  <b>Network Speed</b>: {singleOrderData?.networkSpeed}
                </p>
                <p>
                  <b>Connection Type</b>: {singleOrderData?.connection}
                </p>
                <h6 className="text-black">
                  <b>Payment</b>
                </h6>
                <p className="mb-0">
                  <b>Payment Gateway</b>: {singleOrderData?.paymentGateway}
                </p>
                <p>
                  <b>Payment Method</b>: {singleOrderData?.paymentMethod}
                </p>
                <h6 className="text-black">
                  <b>Contact information</b>
                </h6>
                <p className="mb-0">
                  <strong>
                    <i className="bi bi-envelope"></i>{" "}
                    {
                      singleOrderData?.user?.address?.[
                        singleOrderData?.user?.defaultAddress
                      ]?.email
                    }
                  </strong>
                </p>
                <p className="cursor-pointer" onClick={() => {
                    const phoneNumber = singleOrderData?.user?.address?.[
                      singleOrderData?.user?.defaultAddress
                    ]?.phone;
                    window.location.href = `tel:${phoneNumber}`;
                  }}>
                  <strong>
                    <i className="bi bi-phone-fill"></i>{" "}
                    {
                      singleOrderData?.user?.address?.[
                        singleOrderData?.user?.defaultAddress
                      ]?.phone
                    }
                  </strong>
                </p>
                {singleOrderData?.user?.address?.[
                  singleOrderData?.user?.defaultAddress
                ]?.alternateNumber && (
                  <>
                    <p className="mb-0">
                      <b>Alternate Number</b>
                    </p>
                    <p>
                      <strong>
                        <i className="bi bi-phone-fill"></i>{" "}
                        {
                          singleOrderData?.user?.address?.[
                            singleOrderData?.user?.defaultAddress
                          ]?.alternateNumber
                        }
                      </strong>
                    </p>
                  </>
                )}

                <div className="d-flex justify-content-between">
                  <h6 className="text-black mb-0">
                    <b>Shipping Address</b>
                  </h6>
                  {singleOrderData?.status <= 3 && (
                    <UpdateAddress
                      title={"Update Address"}
                      firstName={singleOrderData?.shippingAddress?.firstName}
                      lastName={singleOrderData?.shippingAddress?.lastName}
                      addressline1={
                        singleOrderData?.shippingAddress?.addressline1
                      }
                      addressline2={
                        singleOrderData?.shippingAddress?.addressline2
                      }
                      city={singleOrderData?.shippingAddress?.city}
                      zipcode={singleOrderData?.shippingAddress?.zipcode}
                      state={singleOrderData?.shippingAddress?.state}
                      phone={singleOrderData?.shippingAddress?.phone}
                      alternateNumber={
                        singleOrderData?.shippingAddress?.alternateNumber
                      }
                      houseNo={singleOrderData?.shippingAddress?.houseNo}
                      email={singleOrderData?.shippingAddress?.email}
                      shippingAddress={true}
                      id={singleOrderData?.id}
                    />
                  )}
                </div>
                <p className="mb-0 text-black">{`${
                  singleOrderData?.shippingAddress?.firstName
                } ${singleOrderData?.shippingAddress?.lastName || ""}`}</p>
                <p className="mb-0 text-black">
                  {singleOrderData?.shippingAddress?.houseNo}
                </p>
                <p className="mb-0 text-black">
                  {singleOrderData?.shippingAddress?.addressline1}
                </p>
                {singleOrderData?.shippingAddress?.addressline2 && (
                  <p className="mb-0 text-black">
                    {singleOrderData?.shippingAddress?.addressline2}
                  </p>
                )}
                <p className="mb-0 text-black">
                  {singleOrderData?.shippingAddress?.city} -{" "}
                  {singleOrderData?.shippingAddress?.zipcode}
                </p>
                <p className="mb-0 text-black">
                  {singleOrderData?.shippingAddress?.state}
                </p>
                <p className="mb-0 text-black cursor-pointer"
                  onClick={() => {
                    const phoneNumber =
                      singleOrderData?.shippingAddress?.alternateNumber ||
                      singleOrderData?.shippingAddress?.phone;
                    window.location.href = `tel:${phoneNumber}`;
                  }}
                >
                  <i className="bi bi-phone-fill"></i>{" "}
                  {singleOrderData?.shippingAddress?.alternateNumber
                    ? singleOrderData?.shippingAddress?.alternateNumber
                    : singleOrderData?.shippingAddress?.phone} 
                </p>
                <p className="text-black mb-0">
                  <i className="bi bi-envelope"></i>{" "}
                  {singleOrderData?.shippingAddress?.email}
                </p>
                <Link
                  target="_blank"
                  to={`https://www.google.com/maps/search/${singleOrderData?.shippingAddress?.addressline1} ${singleOrderData?.shippingAddress?.addressline2}, ${singleOrderData?.shippingAddress?.city}, ${singleOrderData?.shippingAddress?.zipcode}, ${singleOrderData?.shippingAddress?.state}`}
                >
                  {" "}
                  <i className="bi bi-geo-alt"></i> View Map
                </Link>

                <div className="d-flex justify-content-between mt-3">
                  <h6 className="text-black mb-0">
                    <b>Billing Address</b>
                  </h6>
                  {singleOrderData?.status <= 3 && (
                    <UpdateAddress
                      title={"Update Address"}
                      firstName={singleOrderData?.billingAddress?.firstName}
                      lastName={singleOrderData?.billingAddress?.lastName}
                      addressline1={
                        singleOrderData?.billingAddress?.addressline1
                      }
                      addressline2={
                        singleOrderData?.billingAddress?.addressline2
                      }
                      city={singleOrderData?.billingAddress?.city}
                      zipcode={singleOrderData?.billingAddress?.zipcode}
                      state={singleOrderData?.billingAddress?.state}
                      phone={singleOrderData?.billingAddress?.phone}
                      alternateNumber={
                        singleOrderData?.billingAddress?.alternateNumber
                      }
                      email={singleOrderData?.billingAddress?.email}
                      shippingAddress={false}
                      id={singleOrderData?.id}
                      organisationName={
                        singleOrderData?.billingAddress?.organisationName
                      }
                      houseNo={singleOrderData?.billingAddress?.houseNo}
                      gst={singleOrderData?.billingAddress?.gst}
                      billing={true}
                    />
                  )}
                </div>
                <p className="mb-0 text-black">{`${
                  singleOrderData?.billingAddress?.firstName
                } ${singleOrderData?.billingAddress?.lastName || ""}`}</p>
                 <p className="mb-0 text-black">
                  {singleOrderData?.billingAddress?.houseNo}
                </p>
                <p className="mb-0 text-black">
                  {singleOrderData?.billingAddress?.addressline1}
                </p>
                {singleOrderData?.billingAddress?.addressline2 && (
                  <p className="mb-0 text-black">
                    {singleOrderData?.billingAddress?.addressline2}
                  </p>
                )}
                <p className="mb-0 text-black">
                  {singleOrderData?.billingAddress?.city} -{" "}
                  {singleOrderData?.billingAddress?.zipcode}
                </p>
                <p className="mb-0 text-black">
                  {singleOrderData?.billingAddress?.state}
                </p>
                <p className="mb-0 text-black cursor-pointer"
                  onClick={() => {
                    const phoneNumber = singleOrderData?.billingAddress?.phone;
                    window.location.href = `tel:${phoneNumber}`;
                  }}
                >
                  <i className="bi bi-phone-fill"></i>{" "}
                  {singleOrderData?.billingAddress?.phone}
                </p>
                <p className="text-black mb-0">
                  <i className="bi bi-envelope"></i>{" "}
                  {singleOrderData?.billingAddress?.email}
                </p>
                <p className="text-black mb-0">
                  Company: {singleOrderData?.billingAddress?.organisationName}
                </p>
                <p className="text-black mb-0">
                  GST: {singleOrderData?.billingAddress?.gst}
                </p>
                <Link
                  target="_blank"
                  to={`https://www.google.com/maps/search/${singleOrderData?.billingAddress?.addressline1} ${singleOrderData?.billingAddress?.addressline2}, ${singleOrderData?.billingAddress?.city}, ${singleOrderData?.billingAddress?.zipcode}, ${singleOrderData?.billingAddress?.state}`}
                >
                  {" "}
                  <i className="bi bi-geo-alt"></i> View Map
                </Link>
              </div>
            </Col>
          ) : null}
        </Row>
        {singleOrderData?.refund?.length ? (
       <Fragment>
        <Row>
          <Col>
          <h4 className="text-dark mt-4">Refund Amount</h4>
          </Col>
        </Row>
           <Table bordered hover className="text-align-left">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {singleOrderData?.refund?.map((data, index) => {
                return (
                  <Fragment key={index}>
                    <tr>
                      <td>
                        {moment(data?.givenOn).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td>{data?.amount}/-</td>
                      <td>{data?.refcomment}</td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
       </Fragment>
        ) : null}
        {(localStorage.getItem("slug") == "admin" ||
          localStorage.getItem("slug") == "superadmin") && (
          <Row className="mt-5 mb-5">
            <Col md={12}>
              <Table bordered hover className="text-align-left">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {!!singleOrderData?.statusTime &&
                    singleOrderData?.statusTime?.map((data, index) => {
                      // 0 pending,1 cancel, 2 processing,3 verify,4 In_Transit , 5 shipping, 6 completed
                      return (
                        <tr key={index}>
                          <td>
                            {(data?.status === 0 && (
                              <span className="text-primary">
                                <b>Pending</b>
                              </span>
                            )) ||
                              (data?.status === 1 && (
                                <span className="text-danger">
                                  <b>Cancel</b>
                                </span>
                              )) ||
                              (data?.status === 2 && (
                                <span className="text-info">
                                  <b>Processing</b>
                                </span>
                              )) ||
                              (data?.status === 3 && (
                                <span className="text-warning">
                                  <b>Verify</b>
                                </span>
                              )) ||
                              (data?.status === 4 && (
                                <span className="text-success">
                                  <b>In_Transit</b>
                                </span>
                              )) ||
                              (data?.status === 5 && (
                                <span className="text-dark">
                                  <b>Shipping</b>
                                </span>
                              )) ||
                              (data?.status === 6 && (
                                <span className="text-success">
                                  <b>Completed</b>
                                </span>
                              ))}
                          </td>
                          <td>
                            {!!data?.time &&
                              moment(data?.time).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
        {/* Next and Prev button */}
        <Row className="prevNextbtn">
          <Col md={6} sm={6} xs={6} className="p-0">
            <Button 
            onClick={async() => {
              const id = pendingOrder === "true" 
              ? { orderid: singleOrderData?.orderid } 
              : { successid: singleOrderData?.successid };
              const apiResp = await dispatch(nextPrevOrderId({ nextPrev: false, ...id }))
              if(apiResp?.payload?.id){
                pendingOrder === "true" ? navigate(`${orderDetailsUrl}?orderid=${apiResp?.payload?.id}&pending=true`) : navigate(`${orderDetailsUrl}?orderid=${apiResp?.payload?.id}`)
              }
            }}        
            className="w-100 py-2 shadow rounded-0 fw-bold" variant="warning"><i className="bi bi-arrow-left"></i> Prev</Button>
          </Col>
          <Col md={6} sm={6} xs={6} className="p-0">
            <Button 
            onClick={async() => {
              const id = pendingOrder === "true" 
              ? { orderid: singleOrderData?.orderid } 
              : { successid: singleOrderData?.successid };
              const apiResp = await dispatch(nextPrevOrderId({
                nextPrev: true,
                ...id
              }))
              if(apiResp?.payload?.id){
                pendingOrder === "true" ? navigate(`${orderDetailsUrl}?orderid=${apiResp?.payload?.id}&pending=true`) : navigate(`${orderDetailsUrl}?orderid=${apiResp?.payload?.id}`)
              }
            }}        
            className="w-100 py-2 shadow rounded-0 fw-bold" variant="success">Next <i className="bi bi-arrow-right"></i></Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminOrderDetail;
