import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../Components/SideMenu";
import { dashboardReporting } from "../reducers/commonReducer";
import moment from "moment";
import DateSelection from "./DateSelection";

const DashboardReport = () => {
  // const [startDate, setStartDate] = useState()
  // const [endDate, setEndDate] = useState()
  const handleDateRangeChange = ([startDate, endDate]) => {
    // setStartDate(moment(startDate).format("DD/MM/YYYY"));
    // setEndDate(moment(endDate).format("DD/MM/YYYY"));
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      dashboardReporting({
        startDate: moment().format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      })
    );
  }, []);
  const { dashboardReportingData } = useSelector(
    (state) => state.commonReducer
  );
  const totalOrders =
    +(dashboardReportingData?.utmData?.fb || 0) +
    +(dashboardReportingData?.utmData?.Organic || 0) +
    +(dashboardReportingData?.utmData?.ig || 0) +
    +(dashboardReportingData?.utmData?.google || 0);

  const totalamount =
    +(dashboardReportingData?.amountData?.codChargesAmount || 0) +
    +(dashboardReportingData?.amountData?.shippingCost || 0) +
    +(dashboardReportingData?.amountData?.totalPaybleAmount || 0);
  return (
    <>
      <div className="dboard">
        <SideMenu />
        <div className="mt-extra content container-fluid">
          <Row>
            <Col>
              <h6 className="text-danger text-center">
                Before update any name like Categories Name pls check{" "}
                <b>slug</b>.
              </h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} sm={6} xs={6}>
              <DateSelection
                onDateRangeChange={handleDateRangeChange}
                api={dashboardReporting}
              />
            </Col>
            <Col md={8} sm={6} xs={6}>
              <Button
                variant="danger"
                className="float-right"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </Button>
            </Col>
            {/* <Col md={4} className="float-right">
              <label>Select Year</label>
              <Form.Select
                onChange={(e) =>
                  dispatch(
                    dashboardGraphs({
                      year: +e.target.value,
                    })
                  )
                }
              >
                <option>Select Year</option>
                <option value={moment().year()}>{moment().year()}</option>
                <option value={moment().year() - 1}>
                  {moment().year() - 1}
                </option>
                <option value={moment().year() - 2}>
                  {moment().year() - 2}
                </option>
                <option value={moment().year() - 3}>
                  {moment().year() - 3}
                </option>
                <option value={moment().year() - 4}>
                  {moment().year() - 4}
                </option>
                <option value={moment().year() - 5}>
                  {moment().year() - 5}
                </option>
              </Form.Select>
            </Col> */}
          </Row>
          {/* <div className="dashboard-bottom"></div> */}

          <Row>
            <Col md={3}>
              <div className="cards order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-shopping-cart icon-glow"></i> Total
                      Orders
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">{totalOrders}</h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cards order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-calendar-week icon-glow"></i> Amount
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">{totalamount}</h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cards order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-clipboard-list icon-glow"></i>{" "}
                      Prepaid
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">
                      {dashboardReportingData?.paymenttypeData?.Prepaid}{" "}
                      <span className="f-18 text-success">
                        (
                        {(
                          (dashboardReportingData?.paymenttypeData?.Prepaid *
                            100) /
                          totalOrders
                        )?.toFixed(1)}
                        %)
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cards order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-clipboard-list icon-glow"></i> COD
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">
                      {dashboardReportingData?.paymenttypeData?.COD}{" "}
                      <span className="f-18 text-success">
                        (
                        {(
                          (dashboardReportingData?.paymenttypeData?.COD * 100) /
                          totalOrders
                        )?.toFixed(1)}
                        %)
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> AOV
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">
                      {(totalamount / totalOrders)?.toFixed(1)}/-
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {!!dashboardReportingData?.utmData &&
              Object.entries(dashboardReportingData?.utmData).map(
                ([key, value]) => {
                  return (
                    <Col md={3}>
                      <div className="cards payment-card shadow mb-3 animated-card">
                        <div className="cards-body d-flex justify-content-between align-items-center">
                          <div>
                            <p className="cards-title fs-5 text-capitalize">
                              <i className="fas fa-dollar-sign icon-glow"></i>{" "}
                              {key} Order
                            </p>
                          </div>
                          <div>
                            <h4 className="text-dark">{value || 0}</h4>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }
              )}
          </Row>
          <Row>
            <Col md={3}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> CASH
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">
                      {dashboardReportingData?.gatewayReport?.CASH}
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> EASEBUZZ
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">
                    {dashboardReportingData?.gatewayReport?.EASEBUZZ}
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> PAYU
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">
                    {dashboardReportingData?.gatewayReport?.PAYU}
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="cards completed-order-card shadow mb-3 animated-card">
                <div className="cards-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="cards-title fs-5">
                      <i className="fas fa-check-circle icon-glow"></i> RAZORPAY
                    </p>
                  </div>
                  <div>
                    <h4 className="text-dark">
                    {dashboardReportingData?.gatewayReport?.RAZORPAY}
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default DashboardReport;
