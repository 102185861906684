import React, { useEffect, useState } from "react";
import { Button, Row, Form, InputGroup, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addUser,
  editProfileByAdmin,
  getAllStates,
  getCategories,
  getRoles,
  getUsers,
  resetUsersList,
} from "../../reducers/commonReducer";
import UserAction from "./UserAction";
import { adminUserAddressUrl, ordersUrl } from "../../const";
import moment from "moment";

const Users = () => {
  const userType = useLocation().pathname.split("/").filter(Boolean).pop();
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetUsersList());
    let timeOut = setTimeout(() => {
      dispatch(
        getUsers({
          search: search,
          slug:
            (userType === "vendors" && "vender") ||
            (userType === "users" && "customer") ||
            (userType === "checker" && "checker") ||
            (userType === "manager" && "manager"),
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search, userType]);
  useEffect(() => {
    dispatch(getAllStates());
    dispatch(getCategories());
    dispatch(getRoles());
  }, []);
  const { getUsersData, usersBlank } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row className="mb-1">
          <Col md={3}>
            <InputGroup>
              <InputGroup.Text id="inputGroup-sizing-default">
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                aria-label="Default"
                placeholder="Search anything..."
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col md={1} className="float-right">
            <UserAction title="Add" api={addUser} />
          </Col>
          {/* <Col md={2} className="float-right">
            <Link to={``}>
              <Button className="theme-btn">Create Customer</Button>
            </Link>
          </Col> */}
        </Row>
        <Table striped bordered className="table mt-4 w-100 text-align-left">
          <thead>
            <tr className="tables">
              <th className="over-col-size">Personal Info</th>
              <th className="over-col-size">Address</th>
              <th className="over-col-size">Role</th>
              {userType === "vendors" && <th>Category</th>}
              <th className="col-1">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!getUsersData &&
              getUsersData?.map((data, index) => { 
                return (
                  <React.Fragment key={index}>
                    <tr key={data?.id}>
                      <td>
                        <p className="mb-0">
                          <b>Name: </b>
                          <b
                            className={`${data?.spacialuser && `text-success`}`}
                          >
                            {data?.firstName + " " + (data?.lastName || '')}
                          </b>
                        </p>
                        <p className="mb-0">
                          <b>Email: </b>
                          {data?.email}
                        </p>
                        <p className="mb-0">
                          <b>Mobile: </b>
                          {data?.phone}
                        </p>
                        <p className="mb-0">
                          <b>
                            Status:{" "}
                            {data?.status === true ? (
                              <span className="text-white bg-success rounded px-2 f-13">Active</span>
                            ) : (
                              <span className="text-white bg-danger rounded px-2 f-13">Inactive</span>
                            )}
                          </b>
                        </p>
                        <p className="mb-0">
                          <b>Date: </b>
                          {moment(data?.createdAt).format("D MMM YY [at] h:mm a")}
                        </p>
                      </td>
                      <td>
                        <p className="mb-0">
                          <b>Address: </b>
                          {`${
                            data?.address?.[0]?.houseNo || ""
                          } ${
                            data?.address?.[0]?.addressline1 || ""
                          } ${
                            data?.address?.[0]?.addressline2 || ""
                          }`}
                        </p>
                        <p className="mb-0">
                          <b>PIN Code: </b>
                          {data?.address?.[0]?.zipcode}
                        </p>
                        <p className="mb-0">
                          <b>City: </b>
                          {data?.address?.[0]?.city}
                        </p>
                        <p className="mb-0">
                          <b>State: </b>
                          {data?.address?.[0]?.state}
                        </p>
                        <Button variant="warning" className="px-2 py-0" onClick={() => navigate(`${adminUserAddressUrl}?userid=${data?.id}`)}>
                          More Address
                        </Button>
                      </td>
                      <td>{data?.role?.name}</td>
                      {userType === "vendors" && (
                        <td>
                          {data?.category?.map((cat, index) => (
                            <span key={index}>{cat?.name}, </span>
                          ))}
                        </td>
                      )}
                      <td> 
                       {userType === "users" && <Link to={`${ordersUrl}?userid=${data?.id}`}
                        aria-label="Orders">
                          <Button variant="success">Orders</Button>
                        </Link>}
                        <UserAction
                          id={data?.id}
                          firstName={data?.firstName}
                          lastName={data?.lastName}
                          phone={data?.phone}
                          secondPhone={data?.secondPhone}
                          email={data?.email}
                          role={data?.role?.id}
                          city={data?.billingAddress?.city}
                          state={data?.billingAddress?.state}
                          zipcode={data?.billingAddress?.zipcode}
                          addressline1={data?.billingAddress?.addressline1}
                          addressline2={data?.billingAddress?.addressline2}
                          category={data?.category}
                          status={data?.status}
                          api={editProfileByAdmin}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </Table>
        {!!usersBlank && usersBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getUsers({
                    search: search,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default Users;
