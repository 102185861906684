import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu"; 
import { addOffer, getOffersForAdmin, updateOffer} from "../../reducers/commonReducer";
import { home } from "../../const";
import OffersAction from "./OffersAction";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";
import moment from "moment";


const OffersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getOffersForAdmin())
    dispatch(getCategoriesFrontEnd())
  }, []);

  const { offersForAdminData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
      <OffersAction
          title={"Add Offer"}
          popUpTitle="Add New Offer"
          api={addOffer}
        />
      <table className="table mt-2">
          <thead className="normalplace">
            <tr className="tables">
              <th className="over-col-size">Sr.</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Date</th>
              <th className="over-col-size">Categories</th>
              <th className="over-col-size">Products(SKU)</th>
              <th className="col-4">Description</th>
              <th className="over-col-size">Rate</th>
              <th className="over-col-size">OfferType</th>
              <th className="over-col-size">Qty</th>
              <th className="over-col-size">Payment Type</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!offersForAdminData &&
              offersForAdminData?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{moment(data?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{data?.categories?.map((cat , catIndex) => <span key={catIndex}>{cat?.name}, </span>)}</td>
                      <td>{data?.products?.map((product , productIndex) => <b key={productIndex}>{product?.sku}, </b>)}</td>
                      <td>{data?.description}</td>
                      <td>{data?.rate}{data?.discountType === 1 ? '/-' : `%`}</td>
                      <td>
                        {data?.offerType === 1
                          ? 'Cart Qty'
                          : data?.offerType === 2
                          ? 'Coupon'
                          : data?.offerType === 3
                          ? 'Cart Total'
                          : ''}
                      </td>
                      <td>{data?.qty}</td>
                      <td>{data?.paymentType === 1 && 'COD' || data?.paymentType === 2 && 'Prepaid' || data?.paymentType === 3 && 'Both' }</td>
                      <td><b>{data?.status ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</b></td>
                      <td>
                        <OffersAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle = "Update Offer"
                          api = {updateOffer}
                          id = {data?.id}
                          name = { data?.name}
                          description = {data?.description}
                          rate = {data?.rate}
                          status = {data?.status}
                          offerType = {data?.offerType}
                          qty={data?.qty}
                          discountType={data?.discountType}
                          paymentType={data?.paymentType}
                          products={data?.products}
                          categories={data?.categories}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OffersList;
