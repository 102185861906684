import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  adminOrders,
  adminOrdersExport,
  orderDelete,
  orderItemVerify,
  updateOrderStatus,
} from "../../reducers/orderDetailsReducer";
import ExportData from "../../helpers/ExportData";
import SideMenu from "../../Components/SideMenu"; 
import moment from "moment";
import OrderUpdate from "./OrderUpdate";
import { Button, Row, Form, InputGroup, Col } from "react-bootstrap";
import { parseInt } from "lodash";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";
import { allProductListCatWise } from "../../reducers/commonReducer";
import NumberPagination from "../../Pagination";
import ScrollHeight from "../../Components/ScrollHeight";
import { orderDetailsUrl, ordersUrl } from "../../const";
import RefundOrderAmount from "./RefundOrderAmount";
import ShippingAddress from "./ShippingAddress";

const OrdersList = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const pageCountner = queryParameters.get("page");
  const startdate = queryParameters.get("startDate");
  const enddate = queryParameters.get("endDate");
  const status = queryParameters.get("status");
  const catid = queryParameters.get("catid");
  const productid = queryParameters.get("productid");
  const userId = queryParameters.get("userid");
  const width = useRef(window.innerWidth);
  const location = useLocation();
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(pageCountner ? +pageCountner : 1);
  const [orderStatus, setOrderStatus] = useState(status);
  const [categorie, setCategorie] = useState(catid);
  const [productId, setProductId] = useState(productid);
  const [startDate, setStartDate] = useState(startdate);
  const [endDate, setEndDate] = useState(enddate);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    dispatch(getCategoriesFrontEnd())
  },[dispatch])
  useEffect(() => {
    setLoading(true)
    if(catid){
      dispatch(allProductListCatWise({
        catid
      }))
    }
    let timeOut = setTimeout(async() => {
     const apiResp = await dispatch(
        adminOrders({
          search: search,
          page,
          userId,
          startDate,
          endDate,
          catid : categorie,
          productid: productId,
          status: orderStatus
        })
      );
      if(apiResp?.payload?.status){
        setLoading(false)
      }
    }, 50);
    return () => clearTimeout(timeOut);
  }, [search, page, dispatch, userId]);

  const { adminOrdersData, pageCount, adminOrdersExportList } = useSelector((state) => state.orderDetailReducer);
  const { allProductListCatWiseData } = useSelector((state) => state.commonReducer);
  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);
  // combo selection
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allOrderIds = adminOrdersData?.map((order) => order.id);
      setSelectedOrders(allOrderIds);
    } else {
      setSelectedOrders([]);
    }
  };

  // const handleSelectOrder = (event, orderId) => {
  //   if (event.target.checked) {
  //     setSelectedOrders([...selectedOrders, orderId]);
  //   } else {
  //     setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
  //   }
  // };
  const onPageChange = (number) => {
    setPage(number);
  };
  const paginationFilter = {
    search,
    status: orderStatus,
    startDate,
    endDate,
    userId,
    catid : categorie,
    productid: productId
  };
  const header = [
    // "Sr",
    "OrderId",
    "ItemName",
    "ItemQty",
    "Date",
    "TaxableAmount",
    "TaxIGSTAmount",
    "TaxIgst",
    "TaxSGSTAmount",
    "TaxSgst",
    "TaxCGSTAmount",
    "TaxCgst ",
    "TotalAmount",
    "DiscountAnyCoupan",
    "PaymentGateway",
    "PaymentType",
    "PaymentMethod",
    "CODCharges",
    "Address",
    "City",
    "PINCode",
    "State",
    "Status",
    "UtmSource",
    "UtmMedium",
    "UtmAdname",
    "UtmAdsetname",
    "UtmCampaign",
    "Phone",
    "AdminOrderId",
    "Email"
  ];
  const body = [];
  // Temp stop order export
  // !!adminOrdersExportList &&
  //   adminOrdersExportList?.forEach((data) => {
  //     data?.items?.forEach((item) => {
  //       const totalAmount = item?.sale ? item?.sale : item?.mrp;
  //       const taxPerc = parseInt(item?.productId?.tax_class?.percentage);
  //       const gstAmount =
  //         totalAmount - ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100;
  //       const itemName = item?.productId?.name || ""; // Fallback if name is missing
  //       const itemQty = item.quantity;
  //       // Create a row for each quantity unit
  //       for (let i = 0; i < itemQty; i++) {
  //         body.push({
  //           // Sr: index + 1,
  //           OrderId: data?.successid ? data?.successid : data?.orderid,
  //           ItemName: itemName,
  //           Date: moment(data?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
  //           ItemQty: 1, // Each row represents 1 unit
  //           TaxableAmount: gstAmount,
  //           TaxIGSTAmount:
  //             data?.shippingAddress?.state !== "HARYANA"
  //               ? ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100
  //               : "",
  //           TaxIgst:
  //             data?.shippingAddress?.state !== "HARYANA" ? `${taxPerc}%` : "",
  //           TaxSGSTAmount:
  //             data?.shippingAddress?.state === "HARYANA"
  //               ? ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100 / 2
  //               : "",
  //           TaxSgst:
  //             data?.shippingAddress?.state === "HARYANA"
  //               ? `${taxPerc / 2}%`
  //               : "",
  //           TaxCGSTAmount:
  //             data?.shippingAddress?.state === "HARYANA"
  //               ? ((item?.sale ? item?.sale : item?.mrp) * taxPerc) / 100 / 2
  //               : "",
  //           TaxCgst:
  //             data?.shippingAddress?.state === "HARYANA"
  //               ? `${taxPerc / 2}%`
  //               : "",
  //           TotalAmount: totalAmount,
  //           DiscountAnyCoupan: ((((data?.coupondiscount || data?.qtydiscount) + (data?.adminDiscount?.totalDiscount ? data?.adminDiscount?.totalDiscount : 0)) / data?.totalquantity)?.toFixed(2)),
  //           PaymentGateway: data?.paymentGateway,
  //           PaymentType: data?.status === 1 ? "Cancel" : data?.paymenttype,
  //           PaymentMethod: data?.status === 1 ? "Cancel" : data?.paymentMethod,
  //           CODCharges: (data?.codChargesAmount / data?.totalquantity).toFixed(1),
  //           Address: `${data?.shippingAddress?.addressline1} ${
  //             data?.shippingAddress?.addressline12 || ""
  //           }`,
  //           City: data?.shippingAddress?.city,
  //           PINCode: data?.shippingAddress?.zipcode,
  //           State: data?.shippingAddress?.state,
  //           Status: data?.status === 1 ? "Cancel" : (data?.deliveryStatus?.Status
  //             ? data?.deliveryStatus?.Status
  //             : (data?.status === 0 && "Pending") ||
  //               (data?.status === 1 && "Cancel") ||
  //               (data?.status === 2 && "Processing") ||
  //               (data?.status === 3 && "Verify") ||
  //               (data?.status === 4 && "In_Transit") ||
  //               (data?.status === 5 && "Shipping") ||
  //               (data?.status === 6 && "Completed")),
  //           UtmSource: data?.utm_source,
  //           UtmMedium: data?.utm_Medium,
  //           UtmAdname: data?.utm_adname,
  //           UtmAdsetname: data?.utm_adsetname,
  //           UtmCampaign: data?.utm_campaign,
  //           Phone: data?.shippingAddress?.phone,
  //           AdminOrderId: data?.successid,
  //           Email:data?.shippingAddress?.email,
  //         });
  //       }
  //     });
  //   });
  !!adminOrdersExportList && adminOrdersExportList.forEach((data) => {
    let gstAmount = 0; 
    let taxableAmount = 0; 
    let totalAmount =0;
    let taxPerc = 0;
    let totalTaxPerc = 0;
    let taxIGSTAmount = 0;
    const itemDetails = data?.items?.map((item, index) => {
      totalAmount = item?.sale ? item?.sale : item?.mrp;
       taxPerc = parseInt(item?.productId?.tax_class?.percentage);
      // if (isNaN(totalAmount) || totalAmount <= 0) {
      //   totalAmount = 0;
      // }
      // if (isNaN(taxPerc)) {
      //   taxPerc = 0;
      // }
      const calculatedGST = totalAmount - (totalAmount * taxPerc) / 100;
      gstAmount += calculatedGST;  
      taxableAmount += totalAmount;
      let taxiGstAmount = data?.shippingAddress?.state !== "HARYANA" ? (totalAmount * taxPerc) / 100 : "";
      taxIGSTAmount += taxiGstAmount
      totalTaxPerc += parseInt(item?.productId?.tax_class?.percentage);

      return `(${index + 1}) ${item?.productId?.name || ''}\r\nPrice: ${item?.sale || ''}\r\nQuantity: ${item?.quantity || 1}`;
    }).join('\r\n');
    body.push({
      OrderId: data?.successid || data?.orderid,
      ItemDetails: itemDetails,  
      // Date: moment(data?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      Date: moment(data?.successDate ? data?.successDate : data?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      ItemQty: data?.totalquantity,
      TaxableAmount: gstAmount,  
      TaxIGSTAmount: taxIGSTAmount,
      TaxIgst: data?.shippingAddress?.state !== "HARYANA" ? `${totalTaxPerc}%` : "",
      TaxSGSTAmount: data?.shippingAddress?.state === "HARYANA"
        ? (taxableAmount * totalTaxPerc) / 100 / 2
        : "",
      TaxSgst: data?.shippingAddress?.state === "HARYANA" ? `${totalTaxPerc / 2}%` : "",
      TaxCGSTAmount: data?.shippingAddress?.state === "HARYANA" ? (taxableAmount * totalTaxPerc) / 100 / 2 : "",
      TaxCgst: data?.shippingAddress?.state === "HARYANA" ? `${totalTaxPerc / 2}%` : "",
      TotalAmount: taxableAmount,
      DiscountAnyCoupan: ((((data?.coupondiscount || data?.qtydiscount) + (data?.adminDiscount?.totalDiscount ? data?.adminDiscount?.totalDiscount : 0)))?.toFixed(2)),
      PaymentGateway: data?.paymentGateway,
      PaymentType: data?.status === 1 ? "Cancel" : data?.paymenttype,
      PaymentMethod: data?.status === 1 ? "Cancel" : data?.paymentMethod,
      CODCharges: (data?.codChargesAmount).toFixed(1),
      Address: `${data?.shippingAddress?.addressline1} ${
        data?.shippingAddress?.addressline12 || ""
      }`,
      City: data?.shippingAddress?.city,
      PINCode: data?.shippingAddress?.zipcode,
      State: data?.shippingAddress?.state,
      Status: data?.status === 1 ? "Cancel" : (data?.deliveryStatus?.Status
        ? data?.deliveryStatus?.Status
        : (data?.status === 0 && "Pending") ||
          (data?.status === 1 && "Cancel") ||
          (data?.status === 2 && "Processing") ||
          (data?.status === 3 && "Verify") ||
          (data?.status === 4 && "In_Transit") ||
          (data?.status === 5 && "Shipping") ||
          (data?.status === 6 && "Completed")),
      UtmSource: data?.utm_source,
      UtmMedium: data?.utm_Medium,
      UtmAdname: data?.utm_adname,
      UtmAdsetname: data?.utm_adsetname,
      UtmCampaign: data?.utm_campaign,
      Phone: data?.shippingAddress?.phone,
      AdminOrderId: data?.orderid,
      Email:data?.shippingAddress?.email,
    });
});

  
  return (
    <>
      <SideMenu />
      <ScrollHeight loading={loading}/>
      <div className="mt-extra content container-fluid">
        <Row>
          <Col md={10}>
          <p><b>Search by Phone, Name, Address, Orderid, AWB, Delivery Status...</b></p>
          </Col>
          {localStorage.getItem("slug") === "admin" && <Col md={2} className="text-align-end">
            <Button variant="warning" onClick={() => navigate(`${orderDetailsUrl}?createByAdmin=true`)}>Create Order</Button>
          </Col>}
        </Row>
        <Row className="mb-1">
          <Col md={3} className="mt-4">
            <InputGroup className="search-wrapper">
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="custom-search-icon"
              >
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                className="animated-search-input"
                placeholder="Search anything..."
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col md={2} sx={6} xs={6}>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                className="animated-search-input"
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                max={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  queryParameters.set("startDate", e.target.value);
                  navigate(`${location.pathname}?${queryParameters.toString()}`, { replace: true });
                  dispatch(
                    adminOrders({
                      userId,
                      endDate,
                      startDate: e.target.value,
                      search,
                      page,
                      catid : categorie,
                      productid: productId
                    })
                  );
                  setStartDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={2} sx={6} xs={6}>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                className="animated-search-input"
                type="date"
                placeholder="End Date"
                name="endtDate"
                value={endDate}
                max={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  queryParameters.set("endDate", e.target.value);
                  navigate(`${location.pathname}?${queryParameters.toString()}`, { replace: true });
                  dispatch(
                    adminOrders({
                      userId,
                      endDate: e.target.value,
                      startDate,
                      search,
                      page,
                      catid : categorie,
                      productid: productId
                    })
                  );
                  setEndDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col
            md={2}
            sx={6}
            xs={6}
            className={width.current > 500 ? '' : "mt-1"}
          >
            <Form.Label>Order Status</Form.Label>
            <Form.Select
              onChange={(e) => {
                queryParameters.set("status", +e.target.value);
                navigate(`${location.pathname}?${queryParameters.toString()}`, { replace: true });
                setOrderStatus(+e.target.value);
                setPage(1);
                dispatch(
                  adminOrders({
                    status: e.target.value === "all" ? "all" : +e.target.value,
                    page : 1,
                    startDate,
                    endDate,
                    catid : categorie,
                    productid: productId
                  })
                );
              }}
              value={status}
              aria-label="Default select example"
              className="fancyy-select-dropdown"
            >
              <option value={"all"}>Select Status</option>
              <option value={"all"} className="text-dark">
                <b>All</b>
              </option>
              {/* <option value={0} className="text-warning">
                  <b>Pending</b>
                </option> */}
              <option value={1} className="text-danger">
                <b>Cancel</b>
              </option>
              <option value={2} className="text-primary">
                <b>Processing</b>
              </option>
              <option value={3} className="text-secondary">
                <b>Verify</b>
              </option>
              <option value={4} className="text-dark">
                <b>In_Transit</b>
              </option>
              <option value={5} className="text-info">
                <b>Shipping</b>
              </option>
              <option value={6} className="text-success">
                <b>Completed</b>
              </option>
            </Form.Select>
          </Col>
          <Col
            md={2}
            sx={3}
            xs={3}
            className={width.current > 500 ? "mt-4 text-end" : "mt-3"}
          >
            <Button
              variant="danger"
              // className="animatedd-clear-btn"
              onClick={() => {
                navigate(ordersUrl);
                window.location.reload()
              }}
            >
              Clear
            </Button>
          </Col>
          <Col
            md={1}
            sx={3}
            xs={3}
            className={width.current > 500 ? "mt-4" : "mt-3"}
          >
            {localStorage.getItem("slug") === "admin" ? (
              <ExportData
                title="Export"
                tableName={"Ranbazaar Orders"}
                header={header}
                body={body}
                api={adminOrdersExport}
                exportRecord={adminOrdersExportList}
                endDate={endDate}
                startDate={startDate}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={3} sx={12} xs={12} className={width.current > 500 ? 'mb-1' : "mt-1"}>
              <Form.Label>Categories</Form.Label>
              <Form.Select
                value={catid}
                onChange={(e) => {
                  queryParameters.set("catid", e.target.value);
                  navigate(`${location.pathname}?${queryParameters.toString()}`, { replace: true });
                  setCategorie(e.target.value);
                  setPage(1);
                  dispatch(
                    adminOrders({
                      status: orderStatus,
                      page : 1,
                      startDate,
                      endDate,
                      catid : e.target.value,
                    })
                  );
                  dispatch(allProductListCatWise({
                    catid : e.target.value
                  }))
                }}
                className="fancyy-select-dropdown"
              >
                <option value={"all"}>Select Categorie</option>
                {
                  !!getCatsFrontEndData && getCatsFrontEndData?.map((cat, index) => <option key={index} value={cat?.id}><b>{cat?.name}</b></option>)
                }
              </Form.Select>
            </Col>
            {categorie && <Col md={3} sx={6} xs={6} className={width.current > 500 ? 'mb-1' : "mt-1"}>
              <Form.Label>Products</Form.Label>
              <Form.Select
                value={productid}
                onChange={(e) => {
                  queryParameters.set("productid", e.target.value);
                  navigate(`${location.pathname}?${queryParameters.toString()}`, { replace: true });
                  setProductId(e.target.value);
                  setPage(1);
                  dispatch(
                    adminOrders({
                      status: orderStatus,
                      page : 1,
                      startDate,
                      endDate,
                      catid : categorie,
                      productid: e.target.value
                    })
                  );
                }}
                className="fancyy-select-dropdown"
              >
                <option value={"all"}>Select Product</option>
                {
                  !!allProductListCatWiseData && allProductListCatWiseData?.map((items, index) => <option key={index} value={items?.id}><b>{items?.name}</b></option>)
                }
              </Form.Select>
            </Col>}
        </Row>
        {localStorage.getItem("slug") === "admin" ? (
          <>
            <Row className="no-more-tables no-more-tables-font">
              <table className={width.current > 500 ? "table w-100" : null}>
                <thead>
                  <tr className="tables">
                    {/* <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={adminOrdersData.length > 0 && selectedOrders.length === adminOrdersData.length}
                />
                </th> */}
                    <th>Order</th>
                    <th>AWB / WayBillNo</th>
                    <th>AdminOrderId</th>
                    <th>Items</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th>Payment Status</th>
                    <th>Gateway/Method</th>
                    <th>Customer</th>
                    <th>Source</th>
                    <th>Delivery Method</th>
                    <th>Order Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="tbody-table">
                  {!!adminOrdersData &&
                    adminOrdersData?.map((orders, index) => {
                      let deliveryStatus = (typeof orders?.deliveryStatus?.Status === "string" ? orders?.deliveryStatus?.Status : orders?.deliveryStatus?.Status?.Status)
                      let orderItemQty = 0;
                      orders?.items?.forEach((item) => {
                        orderItemQty += item.quantity;
                      });
                      // orderQty += orderItemQty;
                      return (
                        <tr key={index} className="mbl-table-card">
                          {/* <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleSelectOrder(e, orders.id)}
                          checked={selectedOrders.includes(orders.id)}
                        />
                      </td> */}
                          <td
                            data-title="Order"
                            className={orders?.orderCreatedByAdmin ? `text-success button-pointer` : `button-pointer`}
                            onClick={() => {
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`);
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }}                            
                          >
                            <b className="f-16">
                              {orders?.successid
                                ? orders?.successid
                                : orders?.orderid}
                            </b>
                          </td>
                          {orders?.shippingCancel ? <td data-title="AWB / WayBillNo"><b className="text-danger">ShippingCancel</b></td> : (width.current < 668 ? (
                            orders?.waybillno !== "" && (
                              <td data-title="AWB / WayBillNo">
                                {orders?.otherWayBills?.length
                                  ? orders?.otherWayBills?.map(
                                      (billnumber, bilIndex) => (
                                        <span key={bilIndex}>
                                          {billnumber?.waybillno},{" "}
                                        </span>
                                      )
                                    )
                                  : orders?.waybillno
                                  ? orders?.waybillno
                                  : orders?.shiplog?.remarks?.[0]}
                              </td>
                            )
                          ) : (
                            <td data-title="AWB / WayBillNo">
                              {orders?.otherWayBills?.length
                                ? orders?.otherWayBills?.map(
                                    (billnumber, bilIndex) => (
                                      <span key={bilIndex}>
                                        {billnumber?.waybillno},{" "}
                                      </span>
                                    )
                                  )
                                : orders?.waybillno
                                ? orders?.waybillno
                                : orders?.shiplog?.remarks?.[0]}
                            </td>
                          ))}
                          <td data-title="AdminOrderId">{orders?.orderid}</td>
                          <td
                            data-title="Items"
                            className="button-pointer"
                            onClick={() =>{
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`)
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }
                            }
                          >
                            {orderItemQty}
                          </td>
                          <td
                            data-title="Date"
                            className="button-pointer"
                            onClick={() =>{
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`)
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }
                            }
                          >
                            {moment(orders?.successDate ? orders?.successDate : orders?.createdAt).format(
                              "D MMM [at] h:mm a"
                            )}
                            {/* {moment(orders?.createdAt).format(
                              "D MMM [at] h:mm a"
                            )} */}
                          </td>
                          {/* <td data-title="Order">₹ {orders?.payment}</td>
                      <td data-title="Order"><span className={`rounded px-2 f-13 ${orders?.paymenttype === "PENDING" && `bg-warning`} ${orders?.paymenttype === "COD" && `bg-info`}`}>{((orders?.paymenttype === "Prepaid") ? "Paid" : orders?.paymenttype)}</span></td> */}
                          <td
                            data-title="Total"
                            className="button-pointer"
                            onClick={() =>{
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`)
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }
                            }
                          >
                            ₹{" "}
                            {(orders?.totalPaybleAmount +
                              orders?.codChargesAmount + orders?.shippingCost)?.toFixed(0)}
                          </td>
                          <td
                            data-title="Payment Status"
                            className="button-pointer"
                            onClick={() =>{
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`)
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }
                            }
                          >
                            <span
                              className={`rounded px-2 f-13 ${
                                orders?.paymenttype === "PENDING" &&
                                `bg-warning`
                              } ${orders?.paymenttype === "COD" && `bg-info`}`}
                            >
                              {((orders?.paymenttype === "Prepaid" || (orders?.paymenttype === "COD" && orders?.status === 6))  && "Paid") ||
                                (orders?.paymenttype === "COD" && "UnPaid") ||
                                (orders?.paymenttype === "PENDING" &&
                                  "PENDING")}
                            </span>
                          </td>
                          <td
                            data-title="Gateway/Method"
                            className="button-pointer"
                            onClick={() =>{
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`)
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }
                            }
                          >
                            {orders?.paymentGateway} / {orders?.paymentMethod}
                          </td>
                          <td
                            data-title="Customer"
                            className="button-pointer"
                            onClick={() =>{
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`)
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }
                            }
                          >
                            <b>
                              {orders?.shippingAddress?.firstName
                                ? `${orders?.shippingAddress?.firstName} ${
                                    orders?.shippingAddress?.lastName || ""
                                  }`
                                : "No Customer"}
                            </b>{" "}
                            <span className={"f-right"}>
                              Total Orders:{" "}
                              <b>{orders?.user?.orderCounter?.total}</b>
                            </span>
                          </td>
                          <td data-title="Source">
                          {orders?.utm_source?.length > 0 ? orders?.utm_source : "Organic"} {orders?.utm_Medium ? `/` : null} {orders?.utm_Medium}
                          </td>
                          <td
                            data-title="Delivery Method"
                            onClick={() =>{
                              navigate(`${orderDetailsUrl}?orderid=${orders?.id}`)
                              localStorage.setItem("orderListUrl", `${location.pathname || ''}${location.search || ''}`);
                            }
                            }
                            className="f-16 button-pointer"
                          >
                            <b>{orders?.paymenttype}</b>
                          </td>
                          <td data-title="Order Status">
                            {
                              orders?.shippinghold && <><b className="text-danger">Hold</b><br/></>
                            }
                            <span
                              className={orders?.status === 1 ? "text-dark bg-danger rounded px-2 f-13" : (orders?.deliveryStatus?.Status ?
                                ((deliveryStatus === "Delivered" &&
                                  "text-dark bg-success rounded px-2 f-13 ") ||
                                (deliveryStatus === "Manifested" &&
                                  "text-dark bg-primary rounded px-2 f-13") ||
                                (deliveryStatus === "Not Picked" &&
                                  "text-dark bg-warning rounded px-2 f-13") ||
                                (deliveryStatus === "In Transit" &&
                                    "text-white bg-dark rounded px-2 f-13") ||
                                (deliveryStatus === "Pending" &&
                                      "text-dark bg-primary rounded px-2 f-13") ||
                                (deliveryStatus === "Dispatched" &&
                                        "text-dark bg-success rounded px-2 f-13") ||
                                (deliveryStatus === "RTO" &&
                                          "text-dark bg-danger rounded px-2 f-13"))
                                :
                                ((orders?.status === 0 &&
                                  "text-dark bg-primary rounded px-2 f-13 ") ||
                                (orders?.status === 1 &&
                                  "text-dark bg-danger rounded px-2 f-13") ||
                                (orders?.status === 2 &&
                                  "text-dark bg-warning rounded px-2 f-13") ||
                                (orders?.status === 3 &&
                                  "text-dark bg-secondary rounded px-2 f-13") ||
                                (orders?.status === 4 &&
                                  "text-white bg-dark rounded px-2 f-13") ||
                                (orders?.status === 5 &&
                                  "text-dark bg-info rounded px-2 f-13") ||
                                (orders?.status === 6 &&
                                  "text-dark bg-success rounded px-2 f-13")))
                              }
                            >
                              {/* deliveryStatus */}
                              {/* 0 pending,1 cancel, 2 processing, 3 verify 4 In_Transit , 5 shipping, 6 completed */}
                              <b className="td1 ">
                                {orders?.status === 1 ? "Cancel" : (orders?.deliveryStatus?.Status
                                  ? deliveryStatus
                                  : (orders?.status === 0 && "Pending") ||
                                    (orders?.status === 1 && "Cancel") ||
                                    (orders?.status === 2 && "Processing") ||
                                    (orders?.status === 3 && "Verify") ||
                                    (orders?.status === 4 && "In_Transit") ||
                                    (orders?.status === 5 && "Shipping") ||
                                    (orders?.status === 6 && "Completed"))}
                              </b>
                            </span>
                          </td>
                          <td
                            data-title="Action"
                            className="d-flex justify-content-center"
                          >
                            <Link
                               to={`${orderDetailsUrl}?orderid=${orders?.id}`}
                               onClick={() => {
                                 localStorage.setItem(
                                   "orderListUrl",
                                   `${location.pathname || ""}${location.search || ""}`
                                 );
                               }}
                            >
                              <Button
                                className={
                                  width.current < 668
                                    ? "px-1 py-0 bg-thememain btn-mbl-text"
                                    : "m-1 bg-thememain btn-mbl-text"
                                }
                              >
                                <i className="bi bi-eye-fill"></i>
                              </Button>
                            </Link>
                            <OrderUpdate
                              title={"Update Order Status"}
                              orderStatus={orders?.status}
                              orderId={orders?.id}
                              listingApi={adminOrders}
                              cod={orders?.codChargesAmount}
                              paymentStatusUpdate={orders?.paymenttype}
                            />
                           <RefundOrderAmount
                              title={"Amount Refund"}
                              orderId={orders?.id}
                              listingApi={adminOrders}
                           />
                            {localStorage.getItem("username") ===
                              "master@example.com" &&
                              orders?.status === 1 &&
                              index === 0 && (
                                <Button
                                  variant="danger"
                                  className="m-1 btn btn-danger"
                                  onClick={() => {
                                    const alertResp = window.confirm(
                                      "Are You sure Delete this order."
                                    );
                                    if (alertResp) {
                                      dispatch(
                                        orderDelete({
                                          id: orders?.id,
                                        })
                                      );
                                      window.location.reload();
                                    }
                                  }}
                                >
                                  <i className="bi bi-trash"></i>
                                </Button>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Row>
          </>
        ) : (
          <Row className="mb-1">
            {!!adminOrdersData &&
              adminOrdersData?.map((orders, index) => (
                <Col sm={12} xs={12} md={4} key={index} className="mb-3">
                  <div className={`${orders?.status >= 2 ? `bg-gray` : 'bg-danger'} p-2 rounded`}>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-black">
                        ID:-{" "}
                        <b>
                          {orders?.successid
                            ? orders?.successid
                            : orders?.orderid}
                        </b>
                      </h6>
                      <p className="text-dark">
                        <b>{orders.status === 0 && "Pending" || orders.status === 1 && "Cancel" ||
                        orders.status === 2 && "Processing"|| orders.status === 3 && "Verify" || 
                        orders.status === 4 && "In_Transit" || orders.status === 5 && "Shipping" ||
                        orders.status === 6 && "Completed"}</b>
                      </p>
                      <p className={`${orders?.paymenttype === "Prepaid" ? `text-success` : `text-danger`}`}><b>{orders?.paymenttype}</b></p>
                      <p className="mb-0 text-dark"><b>{moment(orders?.successDate ? orders?.successDate : orders?.createdAt).format('YYYY-MM-DD')}</b></p>
                      {orders?.status >= 2 && <>
                        <Button onClick={async() =>{
                        const rsp = window.confirm("Are you sure this order is cancelled?")
                        if(rsp){
                          const apiResp = await dispatch(updateOrderStatus({
                            status:1,
                            id : orders?.id
                          }))
                          if(apiResp?.payload?.status){
                            dispatch(
                              adminOrders({
                                page,
                                userId,
                                startDate,
                                endDate,
                                catid : categorie,
                                productid: productId,
                                status: orderStatus
                              })
                            );
                          }
                        }
                      }} className="p-1" variant="danger">
                        <i className="bi bi-x-circle"></i>
                      </Button>
                      <Button className="p-0" onClick={async() =>{
                        const rsp = window.confirm(`Are you sure this order is ${orders?.shippinghold ? `UnHold` : `Hold`}?`)
                        if(rsp){
                          const apiResp = await dispatch(orderItemVerify({
                            shippinghold: !orders?.shippinghold,
                            orderid : orders?.id
                          }))
                          if(apiResp?.payload?.status){
                            dispatch(
                              adminOrders({
                                page,
                                userId,
                                startDate,
                                endDate,
                                catid : categorie,
                                productid: productId,
                                status: orderStatus
                              })
                            );
                          }
                        }
                      }} variant={orders?.shippinghold ? "success" : "warning"}><b>{orders?.shippinghold ? 'UnHold' : 'Hold'}</b></Button>
                      </>}
                    </div>
                    {orders?.items?.map((item, itemIndex) => (
                      <Fragment key={itemIndex}>
                        <div className="bg-white mb-2 rounded p-1">
                          <Button
                            variant={item?.verifyStatus ? "success" : "warning"}
                            className="p-1"
                            onClick={async () => {
                                const apiResp = await dispatch(
                                  orderItemVerify({
                                    orderid: orders?.id,
                                    index: itemIndex,
                                    status: !item?.verifyStatus,
                                  })
                                );
                                if (apiResp?.payload?.status) {
                                  dispatch(
                                    adminOrders({
                                      page,
                                      userId,
                                      startDate,
                                      endDate,
                                      catid : categorie,
                                      productid: productId,
                                      status: orderStatus
                                    })
                                  );
                                }
                            }}
                          >
                            {item?.verifyStatus ? "Verified" : "Verify"}
                          </Button>
                          {!item?.verifyStatus && (
                              <Button  
                                onClick={() => {
                                  navigate(`/admin/product/${item?.productId?.slug}?orderid=${orders?.id}&itemIndex=${itemIndex}&productType=true`)
                                  localStorage.setItem("checkerOrderListUrl", `${location.pathname || ''}${location.search || ''}`);
                                }}
                                variant="warning" 
                                className="p-1 f-right">
                                <i className="bi bi-pen-fill"></i>
                              </Button>
                          )}
                          <ShippingAddress userid={orders?.user?.id} orders={orders?.user?.orderCounter?.total} shippingAddress={orders?.shippingAddress} orderId={orders?.successid
                            ? orders?.successid
                            : orders?.orderid}/>
                          {!!orders?.user?.orderCounter?.total && <Button onClick={() =>
                              window.open(`${ordersUrl}?userid=${orders?.user?.id}`, '_blank')
                            } variant="success" className="f-right p-1">
                            {orders?.user?.orderCounter?.total}
                          </Button>}
                          <img
                            key={itemIndex}
                            src={item?.mergeimg}
                            alt={item?.productId?.name}
                            className="mb-2"
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </Col>
              ))}
          </Row>
        )}
        <Row className="mt-3">
        <Col>
          <NumberPagination
            pageCount={pageCount}
            currentPage={page}
            api={adminOrders}
            onPageChange={onPageChange}
            pageurl={ordersUrl}
            filters={paginationFilter}
            // productId={productId}
          />
        </Col>
      </Row>
      </div>
    </>
  );
};

export default OrdersList;
