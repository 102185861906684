import React, { useRef, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import dummy from "../../images/dummy.jpeg";
import { handleContextMenu, productRating } from "../../const";
import CustomizeProduct from "./CustomizeProduct";

const SingleProduct = ({
  title,
  sale,
  mrp,
  slug,
  productType,
  customizeDetails,
  defaultImage,
  images,
  rating,
  status
}) => {
  const navigate = useNavigate();
  const width = useRef(window.innerWidth);

  // const discountPercentage = sale
  //   ? ((100 * (mrp - sale)) / mrp).toFixed(2)
  //   : null;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <section className={`best__sell ${width.current < 500 ? 'mb-2' : 'pt-15 pb-40 mt-3'}`}>
        <div className="row">
          <div className="col-xl-12">
            <div className="tab-content" id="best-sell-tabContent">
              <div
                className="tab-pane fade show active"
                id="new"
                role="tabpanel"
                aria-labelledby="new-tab"
              >
                <div
                  className="product-grid card m-0"
                  style={{
                    borderRadius: "15px",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease",
                    background: "linear-gradient(145deg, #f3f4f6, #ffffff)",
                  }}
                >
                  <div className="product-image">
                    <div
                      className="card-img-actions"
                      style={{
                        transition: "transform 0.3s ease",
                        borderRadius: "30px",
                      }}
                    >
                      <Link
                        to={`/product/${slug}?productStatus=${status}`}
                        className="text-default mb-1"
                        aria-label={`View details about ${title}`}
                      >
                        {productType === "customize" ? (
                          <CustomizeProduct
                            coloums={4}
                            customizeDetails={customizeDetails}
                          />
                        ) : (
                          <img
                            onContextMenu={handleContextMenu}
                            src={images ? images[defaultImage] : dummy}
                            className="img-fluid"
                            alt={title}
                            style={{
                              transform: "scale(1)",
                              transition: "transform 0.3s ease",
                              
                            }}
                            loading="lazy"
                          />
                        )}
                      </Link>
                    </div>
                    {!!sale && (
                      <span className="product-discount-label">
                        -{((100 * (mrp - sale)) / mrp).toFixed(0)}%
                      </span>
                    )}
                    <ul className="product-links rounded d-none">
                      <li onClick={(e) => navigate(`/product/${slug}?productStatus=${status}`)}>
                        <Link to="" aria-label="search">
                          <i className="bi bi-search"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="product-content"
                    
                  >
                    <button
                      className="add-to-cart rounded"
                      onClick={() => navigate(`/product/${slug}?productStatus=${status}`)}
                    >
                      {productType === "customize"
                        ? `Customize & Add To Cart`
                        : `Add to Cart`}
                    </button>
                    <Link
                      to={`/product/${slug}?productStatus=${status}`}
                      className="text-default mb-2"
                      aria-label={`View details about ${title}`}
                    >
                      <div className="card-body">
                        <div className="product-name-box">
                          <b className="product-name">
                            {" "}
                            {title.length > 56
                              ? title.slice(0, 56) + "..."
                              : title}{" "}
                          </b>

                          <span></span>
                        </div>
                        <hr />
                        <ul className="mb-1">{rating > 0 ? productRating(Math.round(rating)) : null}</ul>
                        <h3
                          className="mb-0 font-weight-semibold product__price text-success"
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#10653D",
                          }}
                        >
                          <span
                            className="discount-price "
                            style={{
                              fontSize: "16px",
                              textDecoration: "line-through",
                              color: "#AD1A2C",
                            }}
                          >
                            Rs.{mrp}
                          </span>{" "}
                          Rs.{sale}
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Suspense >
  );
};

export default SingleProduct;