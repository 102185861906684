export const home = "/";
export const thankyou = "/thankyou";
export const checkout = "/checkout";
export const about = "/about";
export const shop = "/shop";
export const signup = "/signup";
export const addUserAddress = "/add-user-address";
export const getUserAddress = "/user-address";
export const userDashboard = "/user-dashboard";
export const contact = "/contact";
export const roles = "/roles";
export const product = "/product";
export const warranty = "/warranty";
export const forgotPass = "/forgot-password";
export const phoneVerify = "/phone-verify";
export const verifyOtp = "/verify-otp";
export const newPassword = "/create-new-password";
export const connection = "/connect";
//admin urls
export const userCartList = "/admin/user-cart";
export const loginUrl = "/admin/login";
export const usersUrl = "/admin/users";
export const adminUserAddressUrl = "/admin/admin-user-address";
export const managerUrl = "/admin/manager";
export const checkerUrl = "/admin/checker";
export const ordersUrl = "/admin/orders";
export const trackersUrl = "/admin/trackers";
export const offersUrl = "/admin/offers";
export const pendingOrdersUrl = "/admin/pending-orders";
export const orderDetailsUrl = "/admin/order-details";
export const EmailTemplatesUrl = "/admin/email-templates";
export const categoriesUrl = "/admin/categories";
export const bannerUrl = "/admin/banners";
export const vendorUrl = "/admin/vendor";
export const couponsUrl = "/admin/coupons";
export const settingsUrl = "/admin/settings";
export const permissionsUrl = "/admin/permissions";
export const reviewsUrl = "/admin/reviews"; 
export const addProductUrl = "/admin/add-product";
export const customizeProductImgUrl = "/admin/customize-product-img";
export const productLabelsUrl = "/admin/product-labels";
export const packageListUrl = "/admin/package";
export const codListUrl = "/admin/cod";
export const fileSettingsUrl = "/admin/file-setting";
export const warehouseUrl = "/admin/warehouse";
export const productListUrl = "/admin/product-list";
export const productEditUrl = "/admin/product-edit/:slug";
export const infoPagesUrl = "/admin/pages";
export const orderPdfUrl = "/admin/order-pdf";
export const invoicePdfUrl = "/admin/invoice-pdf";
export const dashboardUrl = "/admin/dashboard";
export const dashboardReportUrl = "/admin/dashboard-report";
export const dashboardReport = "/admin/dashboard";
export const attributesUrl = "/admin/attributes";
export const socialMediaSettingUrl = "/admin/social-media-setting";
export const taxClassesUrl = "/admin/tax-classes";
export const shippingChargesUrl = "/admin/shipping-charges";
export const rewardSlabUrl = "/admin/reward-slab";
//current path
export const currentUrl = window.location.href;
export const currentPath = window.location.pathname;
//baseurl
export const baseUrl = "https://www.ranbazaar.com/";
// common Functions
export const productRating = (times) => {
  var repeatedString = "";
  while (times > 0) {
    repeatedString += "⭐";
    times--;
  }
  return (
    <li
      dangerouslySetInnerHTML={{ __html: !!repeatedString && repeatedString }}
    />
  );
};

//image  right click disabled
export const handleContextMenu = (event) => {
  event.preventDefault();
};
//rating
export const dynamicPriceRange = (price) => {
  const keys = Object.keys(price);
  const lastKey = keys[keys.length - 1];
  const lastValue = price[lastKey];
  return lastValue;
};
export const monthsName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const openNewTab = (url) => {
  window.open(url, "_blank");
};
export const openTabWithRefresh = (url) => {
  window.open(url);
};
// export const handleDownload = (fileUrl) => {
//   console.log("fileUrl", fileUrl)
//   const anchor = document.createElement("a");
//   anchor.href = fileUrl;
//   anchor.download = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
//   document.body.appendChild(anchor);
//   anchor.click();
//   document.body.removeChild(anchor);
// };

export const handleDownload = (fileUrl) => {
  const anchor = document.createElement("a");
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url); 
    })
    .catch((error) => console.error("Error downloading the file:", error));
};


export const convertImageToBlob = async(imageSrc) => {
  try {
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error converting image to blob:', error);
  }
}

export const calculateImageSize = (width, coloums) => {
  let cheight = "0px";
  let widthSize = "0px";

  if (width < 320) {
    if(coloums === 1){
      cheight = "300px";
      widthSize = "300px";
    }else{
      cheight = "135px";
      widthSize = "135px";
    }
  } else if (width >= 320 && width < 500) {
    const baseHeight = (coloums === 1) ? 300 : 135;
    const increment = width - 320;
    cheight = `${baseHeight + (coloums === 1 ? increment : increment / 2)}px`;
    widthSize = `${baseHeight + (coloums === 1 ? increment : increment / 2)}px`;
  } else if (width >= 500 && width < 768) {
    cheight = "215px";
    widthSize = "215px";
  } else if (width >= 768 && width < 1024) {
    cheight = "300px";
    widthSize = "300px";
  } else if (width >= 1024) {
    if (coloums === 4) {
      cheight = "305px";
      widthSize = "305px";
    } else if(coloums === 1){
      cheight = "600px";
      widthSize = "600px";
    } else {
      cheight = "370px";
      widthSize = "370px";
    }
  }

  return { cheight, cwidth: widthSize };
};
//capitalizeText
export function capitalizeText(text) {
  return text?.toLowerCase() // Convert everything to lowercase
    .split(' ') // Split the string by spaces
    .filter(Boolean) // Remove empty spaces if there are extra spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(' '); // Join the words back together
}
export const fontFamilies = [
  "Roboto",
  "Sevillana",
  "Aleo Regular",
  "Elmessiri bold",
  "Gilroy Extrabold",
  "Gilroy Light",
  "GrandHotel Regular",
  "Indigo Outline",
  "Indigo Regular",
  "KozGoPr6N Bold",
  "KozGoPr6N Regular",
  "KozGoPro Regular",
  "Lato Regular",
  "Locomotype GigaSansRegular",
  "MyriadArabic Regular",
  "MyriadHebrew Regular",
  "MyriadPro Regular",
  "NotoSansGurmukhi ExtraCondensed Regular",
  "NunitoSans Regular",
  "Oswald Regular",
  "Pluto Regular",
  "Roboto Slab Regular",
  "Trebuc",
  "Trebucbd",
  "Trebucbi",
  "Trebucit",
  "Amsterdam-1",
  "Amsterdam-2",
  "Amsterdam-3",
  "Amsterdam-4",
  "GreatVibes-Regular",
  "Allura-Regular"
];




