import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import makeAnimated from "react-select/animated";
import { FieldArray, Formik } from "formik";
import Select from "react-select";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getOffersForAdmin } from "../../reducers/commonReducer";
import TagingInput from "../../ComponentsWeb/Sub/TagingInput";
const animatedComponents = makeAnimated();

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  rate: yup.number().required(),
  status: yup.number().required(),
  discountType: yup.number().required(),
  paymentType: yup.number().required(),
  offerType: yup.number().required(),
  qty: yup.number().when("offerType", {
    is: 1,
    then: yup.number().required("Qty is required when offerType is 1"),
    otherwise: yup.number().notRequired(),
  }),
});

function OffersAction({
  title,
  popUpTitle,
  id,
  name,
  description,
  rate,
  status,
  offerType,
  api,
  qty,
  categories,
  products,
  discountType,
  paymentType,
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const [tagGroups, setTagGroups] = useState({
    products: [],
  });
  const handleShow = () => {
    setTagGroups({
      products: products || [],
    });
    setShow(true);
  };
  // taging value selection
  const handleTagsChange = (key, updatedTags, setFieldValue) => {
    setTagGroups((prevTagGroups) => ({
      ...prevTagGroups,
      [key]: updatedTags,
    }));
    setFieldValue(key, updatedTags);
  };
  console.log("categories", tagGroups)
  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);
  return (
    <>
      <Button className="bg-thememain" onClick={handleShow}>
        {title}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popUpTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if (apiResp?.payload?.status) {
              dispatch(getOffersForAdmin());
              handleClose();
              resetForm();
            }
          }}
          initialValues={{
            id,
            name,
            description,
            rate,
            status,
            offerType,
            qty,
            products: tagGroups?.products?.map((productSku) => productSku?.sku),
            categories: categories ? categories?.map((cat) => cat?.id) : [],
            discountType,
            paymentType,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row>
              <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Select OfferType</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("offerType", +e.target.value);
                      }}
                      className="form-control bg"
                      name={`offerType`}
                      value={values.offerType}
                      isInvalid={!!errors.offerType}
                    >
                      <option value="">Offer Type</option>
                      <option value={1}>Cart Qty</option>
                      <option value={2}>Coupon</option>
                      <option value={3}>Cart Total</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.offerType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4} sm={4}>
                  <Form.Group>
                    <Form.Label>Select DiscountType</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("discountType", +e.target.value);
                      }}
                      className="form-control bg"
                      name={`discountType`}
                      value={values.discountType}
                      isInvalid={!!errors.discountType}
                    >
                      <option value="">Discount Type</option>
                      <option value={1}>Amount</option>
                      <option value={2}>Percentage(%)</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.discountType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={4}>
                  <Form.Group>
                    <Form.Label>
                      {values.discountType === 1 ? "Amount" : "Rate in %"}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Rate"
                      name="rate"
                      value={values.rate}
                      onChange={handleChange}
                      isInvalid={!!errors.rate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.rate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={4}>
                  <Form.Group>
                    <Form.Label>Select Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("status", +e.target.value);
                      }}
                      className="form-control bg"
                      name={`status`}
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Select PaymentType</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("paymentType", +e.target.value);
                      }}
                      className="form-control bg"
                      name={`paymentType`}
                      value={values.paymentType}
                      isInvalid={!!errors.paymentType}
                    >
                      <option value="">Payment Type</option>
                      <option value={1}>COD</option>
                      <option value={2}>Prepaid</option>
                      <option value={3}>Both</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.paymentType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {values?.offerType === 1 && (
                  <Col md={6} sm={6}>
                    <Form.Group>
                      <Form.Label>Qty</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Qty"
                        name="qty"
                        value={values.qty}
                        onChange={handleChange}
                        isInvalid={!!errors.qty}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.qty}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              {values.offerType === 1 && <Row className="mb-3">
                <Col md={6}>
                  <Form.Label> Categories </Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        `categories`,
                        selectedOptions?.map((data) => data?.value)
                      );
                    }}
                    options={
                      !!getCatsFrontEndData &&
                      getCatsFrontEndData.map((data) => {
                        return {
                          value: data?.id,
                          label: data?.name,
                        };
                      })
                    }
                    defaultValue={
                      !!getCatsFrontEndData &&
                      getCatsFrontEndData
                        .filter((data) =>
                          values?.categories?.some((cat) => cat === data?.id)
                        )
                        .map((data) => ({
                          value: data?.id,
                          label: data?.name,
                        }))
                    }
                  />
                </Col>
                <Col md={6} sm={6}>
                  <Form.Label className="mb-0">Product SKU</Form.Label>
                  <TagingInput
                    // values={products}
                    values={values?.products}
                    onTagsChange={(tags) =>
                      handleTagsChange("products", tags, setFieldValue)
                    }
                  />
                </Col>
              </Row>}
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default OffersAction;
