import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import UpdateUserProfile from "./UpdateUserProfile";

const UserProfile = () => {
  const { getMyProfileData } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <div className="container-fluid">
        <div className="page-main mbl-pedding-10">
          <div className="section__head d-md-flex justify-content-between mb-40">
            <div className="section__title">
              <h1>My Profile😊😊</h1>
            </div>
          </div>
          {!!getMyProfileData && (
            <Row className="coloum-reverse-mbl">
              <Col md={6} className="mt-2">
                {!!getMyProfileData?.firstName && <div className="border-y mb-4">
                  <h2 className="mb-0">
                    {getMyProfileData?.firstName +
                      " " +
                      (getMyProfileData?.lastName || '')}
                    {/* <UpdateUserProfile
                      title="Update Profile"
                      getMyProfileData={getMyProfileData}
                    /> */}
                  </h2>
                </div>}
                <p>
                  <strong>Phone:</strong> {getMyProfileData?.phone || ''}
                </p>
                <p>
                  <strong>Email:</strong> {getMyProfileData?.email || ''}
                </p>
                {/* <p>
                  <strong>Address:</strong>{" "}
                  {getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.addressline1 +
                    ", " +
                    (!!getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.addressline2
                      ? getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.addressline2 + ","
                      : "") +
                    getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.city +
                    "-" +
                    getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.zipcode +
                    ", " +
                    getMyProfileData?.address?.[getMyProfileData?.defaultAddress]?.state}
                </p> */}
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
