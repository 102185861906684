import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";
import { handleContextMenu } from "../../const";
import Skeleton from "react-loading-skeleton";

const Categories = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    dispatch(getCategoriesFrontEnd());
    setLoading(false)
  }, [dispatch]);

  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);

  const handleToggle = () => {
    setShowAll(!showAll);
  };
  // number of item before show more button
  const isMobile = window.innerWidth < 768;
  const itemsToShow = isMobile && !showAll ? 8 : getCatsFrontEndData.length;

  return loading ? (
    <Row>
      {[...Array(12)].map((_, index) => (
        <Col key={index} xs={3} sm={3} md={1} lg={1} className="text-center">
          <Skeleton circle={true} style={{ height: "10vh", width: "10vh" }} />
          <Skeleton />
        </Col>
      ))}
    </Row>
  ) : (
    <section className="cat-text header-main">
      <div className="container-fluid mb-40 catagories-bg">
        <Row className="justify-content-center">
          {!!getCatsFrontEndData &&
            // getCatsFrontEndData.slice(0, itemsToShow).map((cat, index) => {
            //   const isLastCategory = index === itemsToShow - 1;
            getCatsFrontEndData?.map((cat, index) => {
              return (
                <Col
                  xs={3}
                  sm={3}
                  md={1}
                  lg={1}
                  // className={`pt-2 pb-2 align-center cat-main ${
                  //   isLastCategory ? "last-category" : ""
                  // }`}
                  className={`${width.current > 500 ? `pt-2 pb-2` : `pt-1 pb-1`} align-center cat-main`}
                  key={cat.id}
                >
                  <div className="cat-tab">
                    <Link
                      to={`/shop/${cat.slug}?ref_=${cat.id}&catname=${cat?.name}`}
                    >
                      <div className="cat-image-section">
                        <img
                          src={cat.image}
                          alt=''
                          className="img-fluid"
                          loading="eager"
                          style={{
                            maxHeight: "85%",
                            width: "85%",
                            aspectRatio: "16 / 9",
                            objectFit: "cover", 
                          }}
                          onContextMenu={handleContextMenu}
                        />
                      </div>

                      <div className="cat-text-section">
                        <p
                          style={{
                            margin: 0,
                            fontSize: "12px",
                            overflow: "hidden",
                          }}
                        >
                          {cat.name.length > 33
                            ? cat.name.slice(0, 33) + "..."
                            : cat.name}
                        </p>
                      </div>
                    </Link>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
      {/* {isMobile && getCatsFrontEndData.length > 8 && (
        <div className="text-center mt-3 mb-3">
          <Button className="show-more-btn" onClick={handleToggle}>
            <div className="icon-circle">
              <i
                className={`bi ${
                  showAll ? "bi-chevron-double-up" : "bi-chevron-double-down"
                } ${showAll ? "animate-up" : "animate-down"}`}
              ></i>
            </div>
            {showAll ? "Show Less" : "Show More"}
          </Button>
        </div>
      )} */}
    </section>
  );
};

export default Categories;
