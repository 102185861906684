import React, { useEffect, useState } from "react";
import { Button, Row, Container, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { resetAdminOrderList, userOrders} from "../../../reducers/orderDetailsReducer";
import AddUserReview from "./AddUserReview";
import { Link } from "react-router-dom";
import { reTryPayment } from "../../../reducers/commonReducer";
import "../../../table.css";

const UserOrders = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(resetAdminOrderList());
    dispatch(userOrders());
  }, []);
  const { userOrdersList, userOrdersBlank } = useSelector(
    (state) => state.orderDetailReducer
  );

  return (
        <div className="container-fluid">
          <Row className="">
            <Container className="r-main-tbl">
              <div className="section__head d-md-flex justify-content-between mb-40">
                <div className="section__title">
                  <h2>
                    <b>My Orders😊😊</b>
                  </h2>
                </div>
              </div>
              <div className="no-more-tables">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Date</th>
                      <th>Order ID</th>
                      <th>ORDER VALUE</th>
                      <th>COD</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>View Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!userOrdersList &&
                      userOrdersList.map((orders, index) => {
                        const allReviewsTrue = orders?.items?.every(record => record.review === true);
                        const orderCreateTime = moment(orders?.successDate ? orders?.successDate : orders?.createdAt).local();
                        const today = moment().startOf('day');
                        const isBeforeEndOfDay = orderCreateTime.isSame(today, 'day'); 
                        return (
                          <tr key={index}>
                            <td data-title="Sr No.">
                              {index + 1}.
                            </td>
                            <td data-title="Date">
                              {moment(orders?.successDate ? orders?.successDate : orders?.createdAt).format("DD/MM/YYYY")}
                            </td>
                            {/* <td data-title="Date">
                              {moment(orders?.createdAt).format("DD/MM/YYYY")}
                            </td> */}
                            <td data-title="Order ID">
                              {orders?.successid ? orders?.successid : orders?.orderid}
                            </td>
                            <td data-title="Order Value">
                              {(orders?.totalPaybleAmount)?.toFixed(0)}/-
                            </td>
                            <td data-title="COD">
                              {orders?.codChargesAmount}/-
                            </td>
                            <td data-title="Total">
                              {(orders?.totalPaybleAmount + orders?.codChargesAmount + orders?.shippingCost)?.toFixed(0)}/-
                            </td>
                            <td data-title="Status"
                              className={
                                (orders?.status === 0 && "text-secondary") ||
                                (orders?.status === 2 && "text-primary") ||
                                (orders?.status === 1 && "text-danger") ||
                                (orders?.status === 3 && "text-warning") ||
                                (orders?.status === 4 && "text-success") ||
                                (orders?.status === 5 && "text-warning") ||
                                (orders?.status === 6 && "text-success")
                              }
                            >
                              <b>
                                {orders?.deliveryStatus?.Status ? (typeof orders?.deliveryStatus?.Status === "string" ? orders?.deliveryStatus?.Status : orders?.deliveryStatus?.Status?.Status) : ((orders?.status === 0 && "Pending") ||
                                (orders?.status === 1 && "Cancel") ||
                                  (orders?.status === 2 && "Processing") ||
                                  (orders?.status === 1 && "Cancel") ||
                                  (orders?.status === 3 && "Verify") ||
                                  (orders?.status === 4 && "In_Transit") ||
                                  (orders?.status === 5 && "shipping") ||
                                  (orders?.status === 6 && "completed"))}
                              </b>
                            </td>
                            <td data-title="View Details">
                              <Link
                                to={`/order?orderid=${orders?.id}`}
                              >
                                <Button className="m-1 bg-thememain btn-mbl-text">
                                  <i className="bi bi-eye-fill"></i>
                                </Button>
                              </Link>
                              {(!!localStorage.getItem("x-auth-token") &&
                                (orders?.status === 6) && !allReviewsTrue) && (
                                  <AddUserReview items={orders?.items} orderId={orders?.id}/>
                                )}
                                {/* {((orders?.status === 0) && isBeforeEndOfDay) &&  */}
                               {(orders?.status === 0) && <Button 
                                onClick={async() => {
                                    const apiResp = await dispatch(reTryPayment({
                                      orderid: orders?.id
                                      }))
                                    if(apiResp?.payload?.status){
                                      window.location.replace(apiResp?.payload?.payurl);
                                    }
                                    }} 
                                    variant="warning">Re-Pay</Button>}
                                     {/* } */}
                                 {/* <Button variant="success" className="px-1 py-1" onClick={() => reorderApi(orders?.id)}>Re-Order</Button> */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {!!userOrdersBlank && userOrdersBlank.length > 0 ? (
                  <Row>
                    <Col md={12}>
                    <Button
                    className="w-100"
                      onClick={() => {
                        setPage(page + 1);
                        dispatch(
                          userOrders({
                            page: page + 1,
                          })
                        );
                      }}
                    >
                      Load More
                    </Button>
                    </Col>
                  </Row>
                ) : (
                  <p className="text-center">
                    <b>No record found 😔.</b>
                  </p>
                )}
              </div>
            </Container>
          </Row>
        </div>
  );
};

export default UserOrders;
