import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { getCod } from "../../reducers/commonReducer";
import TagingInput from "../../ComponentsWeb/Sub/TagingInput";
import * as yup from "yup";
const animatedComponents = makeAnimated();

const schema = yup.object().shape({
  id: yup.string().required(),
});

function CodAction({
  title,
  popUpTitle,
  id,
  status,
  api,
  categoriesSlug,
  qty,
  pincode,
  phone,
  productsku, charges, percentage
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // pincode,
  // phone,
  // productsku
  const [tagGroups, setTagGroups] = useState({
    productsku: [],
    phone: [],
    pincode: [],
  });
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setTagGroups({
      productsku: productsku || [], 
      phone: phone || [],
      pincode: pincode || [],
    });
    setShow(true);
  };
  
  const { getCodList } = useSelector((state) => state.commonReducer);
  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);

  const handleTagsChange = (key, updatedTags, setFieldValue) => {
    setTagGroups((prevTagGroups) => ({
      ...prevTagGroups,
      [key]: updatedTags,
    }));
    setFieldValue(key, updatedTags);
  };
  return (
    <>
      <Button className="bg-thememain" onClick={handleShow}>
        {title}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popUpTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if (apiResp?.payload?.status) {
              dispatch(getCod());
              resetForm({ values: "" });
              handleClose();
            }
          }}
          initialValues={{
            id,
            status,
            qty,
            pincode: tagGroups.pincode,
            phone: tagGroups.phone,
            productsku: tagGroups.productsku,
            categoriesSlug,
            charges, percentage
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Label>Rule Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="id"
                    className="form-control bg"
                    value={values.id}
                    isInvalid={!!errors.id}
                  >
                    <option value="">Select Rule Type</option>
                    {!!getCodList &&
                      getCodList?.map((data, index) => {
                        return (
                          <option key={index} value={data?.id}>
                            {data?.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Label className="mb-0">Product SKU</Form.Label>
                  <TagingInput
                    values={productsku}
                    onTagsChange={(tags) =>
                      handleTagsChange("productsku", tags, setFieldValue)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Label className="mb-0">Phone</Form.Label>
                  <TagingInput
                    values={phone}
                    onTagsChange={(tags) => handleTagsChange("phone", tags, setFieldValue)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Label className="mb-0">Pin Code</Form.Label>
                  <TagingInput
                    values={pincode}
                    onTagsChange={(tags) => handleTagsChange("pincode", tags, setFieldValue)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Label> Category </Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        `categoriesSlug`,
                        selectedOptions?.map((data) => data?.value)
                      );
                    }}
                    options={
                      !!getCatsFrontEndData &&
                      getCatsFrontEndData.map((data) => {
                        return {
                          value: data?.slug,
                          label: data?.name,
                        };
                      })
                    }
                    defaultValue={
                      !!categoriesSlug &&
                      categoriesSlug?.map((data) => {
                        if (values.categories?.indexOf(data) != -1) {
                          return {
                            value: data,
                            label: data,
                          };
                        }
                      })
                    }
                  />
                </Col>
              </Row>
              {values.id === "66ed12d0f5fcae595e074996" && <Row className="mb-3">
                <Col md={6} sm={12}>
                      <Form.Label>Charges</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Charges"
                        name="charges"
                        value={values.charges}
                        onChange={handleChange}
                        isInvalid={!!errors.charges}
                      />
                  </Col>
                  <Col md={6} sm={6}>
                    <Form.Label>Percentag</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("percentage", /true/.test(e.target.value));
                      }}
                      name="percentage"
                      className="form-control bg"
                      value={values.percentage}
                      isInvalid={!!errors.percentage}
                    >
                      <option value="">Select Status</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Form.Control>
                  </Col>
              </Row>}
              <Row className="mb-3">
              <Col md={6} sm={12}>
                      <Form.Label>Qty</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Qty"
                        name="qty"
                        value={values.qty}
                        onChange={handleChange}
                        isInvalid={!!errors.qty}
                      />
                  </Col>
                <Col md={6} sm={12}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", /true/.test(e.target.value));
                    }}
                    name="status"
                    className="form-control bg"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default CodAction;
