import React, { useEffect, useRef, useState } from "react";
import "./sub.css";
import "../Sub/common.css";
import logo from "../../images/logo.png";
import whatsapp from "../../images/whatsapp.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import twitter from "../../images/twitter.png";
import youtube from "../../images/youtube.png";
import user from "../../images/user.png";
import cart from "../../images/shopping-cart.png";
import Rewards from "../../images/badge.png";
import logout from "../../images/logout.png";
import Orders from "../../images/shopping-bag.png";
import linked from "../../images/linked.png";
import mail from "../../images/mail.png";
import doticon from "../../images/dot.webp";
import phone from "../../images/phone.png";
import indiamart from "../../images/indiamart.png";
import { CloseButton, Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  getCartlist,
  getMyProfile,
  getProducts,
  getSocialMediaSettings,
  resetProductList,
  updateCart,
} from "../../reducers/commonReducer";
import sumBy from "lodash/sumBy";
import {
  calculateImageSize,
  checkout,
  currentPath,
  dashboardUrl,
  handleContextMenu,
  home,
  login,
  phoneVerify,
  userDashboard,
} from "../../const";

const Header = ({ customizeProductPositionMbl }) => {
  const [lgShow, setLgShow] = useState(false);
  const [search, setSearch] = useState("");
  const [pHeight, setPHeight] = useState();
  const [pWidth, setPWidth] = useState();
  let location = useLocation();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const navigate = useNavigate();
  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current);
    setPWidth(cwidth);
    setPHeight(cheight);
  }, [width.current]);
  useEffect(() => {
    !!localStorage.getItem("x-auth-token") && dispatch(getCartlist());
    !!localStorage.getItem("x-auth-token") && dispatch(getMyProfile());
    dispatch(getSocialMediaSettings());
  }, []);
  const {
    getCartlistData,
    getHomePageSettingList,
    getSocialMediaSettingsData,
  } = useSelector((state) => state.commonReducer);
  const handleClose = () => {
    setLgShow(false);
  };
  let cartDetails = sumBy(
    !!getCartlistData?.list && getCartlistData?.list,
    function (o) {
      return o.quantity;
    }
  );
  return (
    <>
      {/* logo menu */}
      <section
        className={`header-main botm-border ${customizeProductPositionMbl}`}
      >
        <div className="container-fluid">
          <div className="row pt-3 pb-3 mbl-padding-0 align-item-center">
            <Col
              xs={6}
              md={3}
              lg={3}
              className="weblogo align-center mbl-margin-0"
            >
              <Link to={home} aria-label="Go To Home">
                <img
                  className="mbl-site-logo"
                  src={logo}
                  alt="logo"
                  onContextMenu={handleContextMenu}
                  loading="lazy"
                  // width="113" 
                  // height="46"
                />
              </Link>
            </Col>
            <Col className="col-md-5 col-lg-5 search-box mbl-hide">
              <div className="search">
                <input
                  type="text"
                  className="searchTerm"
                  placeholder="What are you looking for?"
                  value={search}
                  onClick={(e) => navigate("/shop?page=1")}
                  onChange={(e) => {
                    dispatch(resetProductList());
                    dispatch(
                      getProducts({
                        search:
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1),
                        height: pHeight,
                        width: pWidth,
                      })
                    );
                    setSearch(
                      e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1)
                    );
                  }}
                />
                {search.length < 2 ? (
                  <button
                    type="submit"
                    className="searchButton"
                    onClick={() => {
                      dispatch(resetProductList());
                      dispatch(
                        getProducts({ search, height: pHeight, width: pWidth })
                      );
                    }}
                  >
                    <i className="bi bi-search"></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="searchButton"
                    onClick={() => {
                      dispatch(resetProductList());
                      dispatch(getProducts({ height: pHeight, width: pWidth }));
                      setSearch("");
                    }}
                  >
                    <CloseButton />
                  </button>
                )}
              </div>
            </Col>
            {/* <Col
              md={2}
              lg={2}
              className="hdphone d-flex justify-content-center mbl-hide"
            >
              <p className="pt-2 pb-2">
                <span>HelpLine:</span>
                <br />
                <span className="font-700">
                  +91 {getSocialMediaSettingsData?.phone}
                </span>
              </p>
            </Col> */}
            <Col
              xs={6}
              md={2}
              lg={2}
              className="d-flex justify-content-end-mbl"
            >
              {/* <Link
                to={
                  localStorage.getItem("x-auth-token") ? ((localStorage.getItem("userRole") === "Admin") ? dashboard : userDashboard) : phoneVerify
                }
              > */}
              <div className="header-cart-right">
                <button
                  onClick={() =>
                    navigate(
                      localStorage.getItem("x-auth-token")
                        ? userDashboard
                        : phoneVerify
                    )
                  }
                  className="btn dropdown-toggle p-12"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                    width="24"  
                    height="24" 
                    src={user}
                    alt="Cart"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />{" "}
                  {localStorage.getItem("x-auth-token") ? "You" : "Login"}
                </button>
                {(localStorage.getItem("x-auth-token") && !currentPath?.includes('/admin/')) && (
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {!localStorage.getItem("x-auth-token") && (
                      <div className="container d-flex ">
                        <span>
                          <p className="my-1">New customer?</p>
                        </span>
                        <span>
                          <span
                            className="dropdown-item text-primary"
                            onClick={() => navigate(phoneVerify)}
                          >
                            Sign Up
                          </span>
                        </span>
                      </div>
                    )}
                    {localStorage.getItem("x-auth-token") && (
                      <span>
                        <span
                          className="dropdown-item"
                          onClick={() =>
                            navigate(`${userDashboard}?eventKeytab=first`)
                          }
                        >
                          <i
                            className="bi bi-profile"
                            alt="Cart"
                            onContextMenu={handleContextMenu}
                            loading="lazy"
                          />
                          My Profile
                        </span>

                        <span
                          className="dropdown-item"
                          onClick={() =>
                            navigate(`${userDashboard}?eventKeytab=second`)
                          }
                        >
                          <img
                            src={Orders}
                            alt="Cart"
                            onContextMenu={handleContextMenu}
                            loading="lazy"
                          />
                          Orders
                        </span>
                        {/* <span  className="dropdown-item" to="#"><img src={Wishlist} alt="Cart" style={{ width: '20px', marginRight: '8px' }} />Wishlist</span> */}
                        {/* <span className="dropdown-item" to="#">
                            <img src={Rewards} alt="Cart" onContextMenu={handleContextMenu}/>
                            Rewards
                          </span> */}
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            const adminToken = localStorage.getItem("x-admin-token");
                            const adminSlug = localStorage.getItem("slug");
                            localStorage.clear();
                            localStorage.setItem("x-admin-token", adminToken);
                            localStorage.setItem("slug", adminSlug);
                            setTimeout(() => {
                              navigate(home);
                              window.location.reload();
                            }, 1000);
                          }}
                        >
                          <img
                            src={logout}
                            alt="Cart"
                            onContextMenu={handleContextMenu}
                            loading="lazy"
                          />
                          LogOut
                        </span>
                      </span>
                    )}
                  </div>
                )}
              </div>
              {/* </Link> */}
              {(localStorage.getItem("x-auth-token") && !currentPath?.includes('/admin/')) && (
                <Link
                  to={getCartlistData?.list?.length >= 1 && checkout}
                  aria-label="Checkout"
                >
                  <div className="header-cart-right position-relative">
                    {cartDetails > 0 && (
                      <span className="cart-badge position-absolute top-0 end-0">
                        {cartDetails}
                      </span>
                    )}

                    <button
                      type="button"
                      className="btn d-none d-md-block my-2"
                      style={{ width: "100px" }}
                    >
                      <img
                        src={cart}
                        alt="Cart"
                        onContextMenu={handleContextMenu}
                        loading="lazy"
                        width="24"  
                        height="24"
                      />
                      Cart
                    </button>

                    <button type="button" className="btn d-md-none p-12" style={{
                      minHeight:'48px', minWidth:'48px'
                    }}>
                      <img
                        src={cart}
                        alt="Cart"
                        onContextMenu={handleContextMenu}
                        loading="lazy"
                        width="24"  
                        height="24"
                      />
                    </button>
                  </div>
                </Link>
              )}

              {/* start checkout popup */}
              <Modal
                size="lg"
                show={lgShow}
                onHide={handleClose}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Your Cart
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <table
                      id="cart"
                      className="table table-hover table-condensed"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "50%" }}>Product</th>
                          <th style={{ width: "10%" }}>Price</th>
                          <th style={{ width: "8%" }}>Quantity</th>
                          <th style={{ width: "22%" }} className="text-center">
                            Subtotal
                          </th>
                          <th style={{ width: "10%" }} />
                        </tr>
                      </thead>
                      <tbody>
                        {!!getCartlistData?.list &&
                          getCartlistData?.list.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td data-th="Product">
                                  <div className="row">
                                    <div className="col-sm-12 hidden-xs">
                                      <img
                                        src={data?.proudct?.images[0]}
                                        alt="..."
                                        className="img-responsive popup-product"
                                        onContextMenu={handleContextMenu}
                                        loading="lazy"
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td data-th="Price">
                                  Rs. {data?.proudct?.sale}
                                </td>
                                <td data-th="Quantity">
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    value={data?.quantity}
                                    onChange={async (e) => {
                                      await dispatch(
                                        updateCart({
                                          id: data?.id,
                                          quantity: +e.target.value,
                                        })
                                      );
                                      dispatch(getCartlist());
                                    }}
                                  />
                                </td>
                                <td
                                  data-th="Subtotal"
                                  className="text-center phone-center"
                                >
                                  Rs. {data?.proudct?.sale * data?.quantity}
                                </td>
                                <td className=" text-center phone-center">
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={async () => {
                                      dispatch(
                                        await updateCart({
                                          id: data?.id,
                                          quantity: 0,
                                        })
                                      );
                                      setTimeout(() => {
                                        dispatch(getCartlist());
                                      }, 500);
                                    }}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <Link to={home} aria-label="Shopping">
                              <span className="btn btn-warning">
                                Continue Shopping
                              </span>
                            </Link>
                          </td>
                          <td colSpan={2} className="hidden-xs text-center">
                            {" "}
                            <strong>Total</strong>{" "}
                          </td>
                          <td className="hidden-xs text-center">
                            <strong>
                              Rs.{" "}
                              {sumBy(
                                !!getCartlistData?.list &&
                                  getCartlistData?.list,
                                function (o) {
                                  return o.proudct?.sale * o?.quantity;
                                }
                              )}
                            </strong>
                          </td>

                          <td>
                            <Link to={checkout} aria-label="Checkout">
                              <span className="btn btn-warning btn-block">
                                Checkout
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Modal.Body>
              </Modal>
              {/* end checkout popup */}
            </Col>
          </div>
        </div>
      </section>
      {!!getHomePageSettingList?.home?.toprunningtext && (
        <section className="marque-text">
          {location?.pathname === "/" && (
            <marquee behavior="alternate" direction="left">
              <p className="mb-0">
                {/* <Link
                  className="whatsapp"
                  target="_blank"
                  to={`https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi, I%27m%20interested%20in%20your%20services&source=&data=&app_absent=`}
                >
                  <i className="bi bi-whatsapp"></i>{" "}
                  {getSocialMediaSettingsData?.whatsapp}{" "}
                </Link> */}
                {Array.from({ length: 6 }).map((_, index) => (
                  <React.Fragment key={index}>
                    <span>{getSocialMediaSettingsData?.topMarqueeTag} </span>{" "}
                    {index < 5 && (
                      <img
                        className="black-icon-dot mx-1"
                        src={doticon}
                        alt="Ranbazaar"
                        loading="lazy"
                      />
                    )}
                  </React.Fragment>
                ))}
              </p>
            </marquee>
          )}
        </section>
      )}
      {(location?.pathname === "/" || location?.pathname === "/shop") && (
        <section>
          <Row>
            <Col className="col-sx-12 col-sm-12 mt-1 desktop-hide">
              <div className="search">
                <input
                  type="text"
                  className="searchTerm"
                  placeholder="What are you looking for?"
                  onClick={() => navigate("/shop?page=1")}
                  onChange={(e) => {
                    dispatch(resetProductList());
                    dispatch(
                      getProducts({
                        search: e.target.value,
                        height: pHeight,
                        width: pWidth,
                      })
                    );
                    setSearch(e.target.value);
                  }}
                />
                {search.length < 2 ? (
                  <button
                    type="submit"
                    className="searchButton text-dark"
                    onClick={() => {
                      dispatch(resetProductList());
                      dispatch(
                        getProducts({ search, height: pHeight, width: pWidth })
                      );
                    }}
                  >
                    <i className="bi bi-search"></i>
                    <b className="search-font"> Search </b>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="searchButton"
                    onClick={() => {
                      dispatch(resetProductList());
                      dispatch(getProducts({ height: pHeight, width: pWidth }));
                      setSearch("");
                    }}
                  >
                    <CloseButton />
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </section>
      )}
      {!!getHomePageSettingList?.home?.floating_icon &&
        getHomePageSettingList.home.floating_icon === true && (
          <ul id="social_side_links" className="side-social-links">
            {getSocialMediaSettingsData?.whatsapp && (
              <li>
                <Link
                  to={`https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi. I am intrested in Your Services..&source=&data=&app_absent=`}
                  target="_blank"
                >
                  <img
                    src={whatsapp}
                    alt="whatsapp"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.facbook && (
              <li>
                <Link
                  to={getSocialMediaSettingsData.facbook}
                  target="_blank"
                  aria-label="facebook"
                >
                  <img
                    src={facebook}
                    alt="facebook"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.instagram && (
              <li>
                <Link
                  to={getSocialMediaSettingsData.instagram}
                  target="_blank"
                  aria-label="instagram"
                >
                  <img
                    src={instagram}
                    alt="instagram"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.twitter && (
              <li>
                <Link
                  to={getSocialMediaSettingsData.twitter}
                  target="_blank"
                  aria-label="twitter"
                >
                  <img
                    src={twitter}
                    alt="twitter"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.youtube && (
              <li>
                <Link
                  to={getSocialMediaSettingsData?.youtube}
                  target="_blank"
                  aria-label="youtube"
                >
                  <img
                    src={youtube}
                    alt="youtube"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.linkdin && (
              <li>
                <Link
                  to={getSocialMediaSettingsData.linkdin}
                  target="_blank"
                  aria-label="linkdin"
                >
                  <img
                    src={linked}
                    alt="linkdin"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.mail && (
              <li>
                <Link
                  to={`mailto:${getSocialMediaSettingsData.mail}`}
                  aria-label="mail"
                >
                  <img
                    src={mail}
                    alt="mail"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.phone && (
              <li>
                <Link
                  to={`tel:+91${getSocialMediaSettingsData.phone}`}
                  aria-label="phone"
                >
                  <img
                    src={phone}
                    alt="phone"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
            {getSocialMediaSettingsData?.indiamart && (
              <li>
                <Link
                  to={getSocialMediaSettingsData.indiamart}
                  target="_blank"
                  aria-label="indiamart"
                >
                  <img
                    src={indiamart}
                    alt="indimart"
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                  />
                </Link>
              </li>
            )}
          </ul>
        )}
    </>
  );
};

export default Header;
