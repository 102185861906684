import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderDetailsUrl } from "../../const";

const schema = yup.object().shape({
  paymenttype: yup.string().required(),
});

function ProductSelection({
  title,
  userid,
  popUpTitle,
  id,
  api,
  stateHide,
  paymenttype,
  products
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { allProductListCatWiseData } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <Button className="bg-thememain w-100" onClick={handleShow}>
      {title ? title : <i className="bi bi-pen-fill"></i>}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popUpTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if(apiResp?.payload?.status && apiResp?.payload?.orderid){
              navigate(`${orderDetailsUrl}?orderid=${apiResp?.payload?.orderid}`)
              window.location.reload();
            }
            // resetForm({ values: "" });
            // await dispatch(getShippings());
            // handleClose();
            // resetForm();

            // Object.entries(products).map(([key, value]) => ({
            //   productId: key,
            //   value: value,
            // }))
          }}
          initialValues={{
            userid,
            cartItems: products
              ? products?.map((item) =>({
                productId: item?.productId?.id, quantity: item?.quantity, price: item?.sale
              }))
              : [{ productId: "", quantity: "", price: "" }],
            paymenttype,
            orderCreatedByAdmin: true,
            id
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Row>
                    {/* <FieldArray name="cartItems">
                      {({ remove, push }) => (
                        <>
                          {!!values.cartItems &&
                            values.cartItems.map((data, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Col md={6} sm={12}>
                                    <Form.Label className="mb-0 mt-2">Product Name</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={handleChange}
                                        className="form-control bg"
                                        name={`cartItems.${index}.productId`}
                                        value={data?.productId}
                                        >
                                        <option value="">Select Product</option>
                                        {!!allProductListCatWiseData &&
                                            allProductListCatWiseData?.map((data, index) => {
                                            return (
                                                <option value={data?.id} key={index}>
                                                {data?.name} <b>({data?.sale}/-)</b>
                                                </option>
                                            );
                                            })}
                                    </Form.Control>
                                  </Col>
                                  <Col md={2} sm={12}>
                                    <Form.Group>
                                      <Form.Label className="mb-0 mt-2">Qty</Form.Label>
                                      <Form.Control
                                        type="number"
                                        onChange={handleChange}
                                        value={data?.value}
                                        name={`cartItems.${index}.quantity`}
                                        className="form-control bg"
                                        isInvalid={!!errors.cartItems}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={2} sm={12}>
                                    <Form.Group>
                                      <Form.Label className="mb-0 mt-2">Qty Price</Form.Label>
                                      <Form.Control
                                        type="number"
                                        onChange={handleChange}
                                        value={data?.value}
                                        name={`cartItems.${index}.price`}
                                        className="form-control bg"
                                        isInvalid={!!errors.cartItems}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={1} sm={12} className="mt-4">
                                    <Button
                                      variant="danger"
                                      onClick={() => remove(index)}
                                    >
                                      <i className="bi bi-trash3-fill"></i>
                                    </Button>
                                  </Col>
                                  <Col md={1} sm={12} className="mt-4">
                                    <Button
                                      variant="success"
                                      onClick={() =>
                                        push({ productId: "", quantity: "", price: "" })
                                      }
                                    >
                                      <i className="bi bi-plus-lg"></i>
                                    </Button>
                                  </Col>
                                </React.Fragment>
                              );
                            })}
                        </>
                      )}
                    </FieldArray> */}
                    <FieldArray name="cartItems">
                      {({ remove, push }) => (
                        <>
                          {!!values.cartItems &&
                            values.cartItems.map((data, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Col md={6} sm={12}>
                                    <Form.Label className="mb-0 mt-2">
                                      Product Name
                                    </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) => {
                                        handleChange(e); // Update the productId
                                        const selectedProduct =
                                          allProductListCatWiseData.find(
                                            (product) =>
                                              product.id === e.target.value
                                          );
                                        if (selectedProduct) {
                                          const updatedPrice =
                                            selectedProduct.sale;
                                          // Update the price in Formik's values
                                          values.cartItems[index].price =
                                            updatedPrice;
                                          setFieldValue(
                                            `cartItems.${index}.price`,
                                            updatedPrice
                                          ); // Set the price field
                                        }
                                      }}
                                      className="form-control bg"
                                      name={`cartItems.${index}.productId`}
                                      value={data?.productId}
                                    >
                                      <option value="">Select Product</option>
                                      {!!allProductListCatWiseData &&
                                        allProductListCatWiseData.map(
                                          (product, i) => {
                                            return (
                                              <option
                                                value={product.id}
                                                key={i}
                                              >
                                               <b>SKU: {product?.sku}</b> <b>({product.sale}/-)</b>
                                              </option>
                                            );
                                          }
                                        )}
                                    </Form.Control>
                                  </Col>
                                  <Col md={2} sm={12}>
                                    <Form.Group>
                                      <Form.Label className="mb-0 mt-2">
                                        Qty
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        onChange={handleChange}
                                        value={data?.quantity}
                                        name={`cartItems.${index}.quantity`}
                                        className="form-control bg"
                                        isInvalid={!!errors.cartItems}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={2} sm={12}>
                                    <Form.Group>
                                      <Form.Label className="mb-0 mt-2">
                                        Qty Price
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        onChange={handleChange}
                                        value={data?.price} // This will now update dynamically
                                        name={`cartItems.${index}.price`}
                                        className="form-control bg"
                                        isInvalid={!!errors.cartItems}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={1} sm={12} className="mt-4">
                                    <Button
                                      variant="danger"
                                      onClick={() => remove(index)}
                                    >
                                      <i className="bi bi-trash3-fill"></i>
                                    </Button>
                                  </Col>
                                  <Col md={1} sm={12} className="mt-4">
                                    <Button
                                      variant="success"
                                      onClick={() =>
                                        push({
                                          productId: "",
                                          quantity: "",
                                          price: "",
                                        })
                                      }
                                    >
                                      <i className="bi bi-plus-lg"></i>
                                    </Button>
                                  </Col>
                                </React.Fragment>
                              );
                            })}
                        </>
                      )}
                    </FieldArray>
                  </Row>
                  <Row className="mt-4">
                    <Col md={6}>
                    <Form.Label>Select Payment Type</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("paymenttype", e.target.value);
                      }}
                      name="paymenttype"
                      className="form-control"
                      value={values.paymenttype}
                      isInvalid={!!errors.paymenttype}
                    >
                      <option value="">Select Product</option>
                      <option value={'COD'}>COD</option>
                      <option value={'Prepaid'}>Prepaid</option>
                      <option value={'PENDING'}>PENDING</option>
                    </Form.Control>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ProductSelection;
