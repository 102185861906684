// import React, { useState, useEffect, useRef, Suspense } from "react";
// import { useSelector } from "react-redux";
// import {calculateImageSize, capitalizeText, handleContextMenu} from "../../const.js"
// import Loader from "../../Components/Loader.js";
// import whiteBg from '../../images/whitebg.jpg'

// const CustomizeProduct = ({ customizeDetails, coloums, page }) => {
//   const width = useRef(window.innerWidth);
//   const [isVisible, setIsVisible] = useState(false);
//   const containerImgRef = useRef(null);
//   // const [loading, setLoading] = useState(true);
//   const [productType, setProductType] = useState(false);
//   const [imageDimensions, setImageDimensions] = useState({
//     width: 0,
//     height: 0,
//   });
//   const { getSingleProductData } = useSelector((state) => state.commonReducer);
//   let customerImg = customizeDetails?.[0]?.customerImg;
//   let inputFields = customizeDetails?.[0]?.inputFields;
//   let customBg = customizeDetails?.[0]?.productbgColor;
//   let bgCustomImage = customizeDetails?.[0]?.productImg;
//   //customize image setting
//   const [customizeHeight, setCustomizeHeight] = useState("0px");
//   const [customizeWidth, setCustomizeWidth] = useState("0px");
//   useEffect(() => {
//     const { cheight, cwidth } = calculateImageSize(width.current, coloums);
//     setCustomizeHeight(cheight);
//     setCustomizeWidth(cwidth);
//   }, [width.current]);
//   //onload customize img
//   useEffect(() => {
//     // setLoading(true)
//     const loadImage = async() => {
//       if (!getSingleProductData) return;
//       const imageUrl = !productType
//         ? getSingleProductData.productImg
//         : getSingleProductData?.customizeDetails?.[
//             getSingleProductData?.defaultSample
//           ]?.productImg;
//       if (!imageUrl) return;
//       const img = new Image();
//       img.src = `${imageUrl}`;
//       img.onload = () => {
//         setImageDimensions({
//           width: img.naturalWidth,
//           height: img.naturalHeight,
//         });
//       };
//     };
//     loadImage();
//     // setLoading(false)
//   }, [getSingleProductData, productType]);
//   //loading time placeholder image
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         console.log("Observer entry:", entry); // Debug
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//         }
//       },
//       {
//         threshold: 0.1, // Adjust if needed
//       }
//     );

//     if (containerImgRef.current) {
//       observer.observe(containerImgRef.current);
//     }

//     return () => {
//       if (containerImgRef.current) {
//         observer.unobserve(containerImgRef.current);
//       }
//     };
//   }, []);
//   console.log("setIsVisible", isVisible)
//   //End Loading time placeholder images
//   useEffect(() => {
//     const loadBgImage = () => {
//       if (!bgCustomImage) return;

//       const img = new Image();
//       img.src = `${bgCustomImage}`;
//       img.onload = () => {
//         setImageDimensions({
//           width: img.naturalWidth,
//           height: img.naturalHeight,
//         });
//       };
//     };

//     loadBgImage();
//   }, [bgCustomImage]);
//   return (
//     <Suspense fallback={<Loader/>}>
//       {
//         !!inputFields ? <div
//         onContextMenu={handleContextMenu} 
//           style={{
//             background: isVisible ? `${customBg}` : '#fff',
//             width : customizeWidth,
//             height : customizeHeight,
//             position: "relative",
//           }}
//         >
//           {/* Image behind the name-slip */}
//           {!!customerImg &&
//             customerImg?.map((img, index) => {
//               let imageSrc =
//                 localStorage.getItem(img.name) || `${img?.imageUrl}`;
//               return (
//                 <div
//                 onContextMenu={handleContextMenu} 
//                   key={index}
//                   className="overflow-hidden"
//                   style={{
//                     position: "absolute",
//                     userSelect: "auto",
//                     width: img.width.endsWith("px") ? img.width : `${img.width}px`,
//                     height: img.width.endsWith("px") ? img.height : `${img.height}px`,
//                     display: "inline-block",
//                     cursor: "move",
//                     zIndex: 1,
//                     transform:`translate(${img.x}px, ${img.y}px)`,
//                     boxSizing: "border-box",
//                     flexShrink: 0,
//                   }}
//                 >
//                   <img
//                     className="w-100"
//                     src={imageSrc}
//                     alt={"Ranbazzar"}
//                     style={{
//                       position: "relative",
//                       top: `${localStorage.getItem(`imageTopBottoms${index}`)}px`,
//                       left: `${localStorage.getItem(`imageLeftRights${index}`)}px`,
//                       transform: `rotate(${localStorage.getItem(`rotations${index}`) || 0}deg) 
//                                   scale(${localStorage.getItem(`imageScales${index}`) || 1}) `,
//                       objectFit: "contain",
//                       height: "100%",
//                       width: "100%",
//                     }}
//                     onContextMenu={handleContextMenu}
//                   />
//                 </div>
//               );
//             })}
//           <div
//             ref={containerImgRef}
//             className="name-slip name-slip-bg"
//             style={{
//               background: isVisible
//               ? `url(${bgCustomImage})`
//               : `url(${whiteBg})`,
//             backgroundSize: "contain !important",
//             width: customizeWidth,
//             height: customizeHeight,
//             backgroundRepeat: "no-repeat",
//             // backgroundPosition: "center center",
//             overflow: "hidden",
//             position: "relative",
//             zIndex: 2,
//             transition: "background 0.2s ease-in-out",
//             }}
//           >
//             {/* input box start */}
//             {!!inputFields &&
//               inputFields.map((field, index) => {
//                 const maxLength = +field?.maxLength;
//                 const storedValue = localStorage.getItem(field?.label?.slug);
//                 let fieldValue = storedValue !== null ? storedValue : field.value;
//                 if (fieldValue.length > maxLength) {
//                   let trimmedValue = fieldValue.substring(0, maxLength);
//                   if (fieldValue[maxLength] !== ' ' && trimmedValue.lastIndexOf(' ') !== -1) {
//                     trimmedValue = trimmedValue.substring(0, trimmedValue.lastIndexOf(' '));
//                   }
//                   fieldValue = trimmedValue;
//                 }
//                 const formattedName = capitalizeText(fieldValue);
//                 const fontSize = field?.style?.fontSize.endsWith("px") ? field?.style?.fontSize : `${field?.style?.fontSize}px`;
//                 return (
//                   <div
//                     key={index}
//                     className="input react-draggable react-draggable-dragged"
//                     style={{
//                       position: "absolute",
//                       userSelect: "auto",
//                       color: field?.style?.color,
//                       fontFamily: field?.style?.fontFamily,
//                       fontWeight: field?.style?.fontWeight,
//                       textAlign: field?.style?.textAlignment,
//                       textTransform: field?.style?.textType,
//                       transform:  `rotate(${field?.style?.rotation || 0}deg)`,
//                       fontSize: fontSize,
//                       width:  field?.width?.endsWith("px") ? field?.width : `${field?.width}px`,
//                       display: "inline-block",
//                       top: `${field?.y}px`,
//                       left: `${field?.x}px`,
//                       cursor: "move",
//                       maxWidth: "9.0072e+15px",
//                       maxHeight: "9.0072e+15px",
//                       boxSizing: "border-box",
//                       flexShrink: "0",
//                       zIndex: 3,
//                     }}
//                   >
//                      {formattedName ? formattedName : fieldValue}
//                   </div>
//                 );
//               })}
//           </div>
//         </div> : <Loader/>
//       }
    
//     </Suspense>
//   );
// };

// export default CustomizeProduct;



import React, { useState, useEffect, useRef, Suspense } from "react";
import { calculateImageSize, capitalizeText, handleContextMenu } from "../../const.js";
import Loader from "../../Components/Loader.js";

const CustomizeProduct = ({ customizeDetails, coloums }) => {
  const width = useRef(window.innerWidth);
  const [isVisible, setIsVisible] = useState(false);
  const [isBgLoaded, setIsBgLoaded] = useState(false);
  const containerImgRef = useRef(null);

  let customerImg = customizeDetails?.[0]?.customerImg;
  let inputFields = customizeDetails?.[0]?.inputFields;
  let customBg = customizeDetails?.[0]?.productbgColor;
  let bgCustomImage = customizeDetails?.[0]?.productImg;

  // Customize image dimensions
  const [customizeHeight, setCustomizeHeight] = useState("0px");
  const [customizeWidth, setCustomizeWidth] = useState("0px");

  useEffect(() => {
    const { cheight, cwidth } = calculateImageSize(width.current, coloums);
    setCustomizeHeight(cheight);
    setCustomizeWidth(cwidth);
  }, [width.current]);

  // Load background image
  useEffect(() => {
    if (!bgCustomImage) return;
    const img = new Image();
    img.src = bgCustomImage;
    img.onload = () => setIsBgLoaded(true);
  }, [bgCustomImage]);

  // Intersection Observer for visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );

    if (containerImgRef.current) observer.observe(containerImgRef.current);

    return () => {
      if (containerImgRef.current) observer.unobserve(containerImgRef.current);
    };
  }, []);
  if (!isBgLoaded) return <div style={{
    background: "#fff",
    width: customizeWidth,
    height: customizeHeight,
  }} />;
  // End Intersection Observer for visibility
  return (
    <Suspense fallback={<Loader style={{ width: "300px", height: "300px" }} />}>
      {inputFields ? (
        <div
          onContextMenu={handleContextMenu}
          style={{
            background: isVisible ? `${customBg}` : "#fff",
            width: customizeWidth,
            height: customizeHeight,
            position: "relative",
          }}
        >
          {!!customerImg &&
            customerImg.map((img, index) => {
              let imageSrc = localStorage.getItem(img.name) || `${img?.imageUrl}`;
              return (
                <div
                  onContextMenu={handleContextMenu}
                  key={index}
                  className="overflow-hidden"
                  style={{
                    position: "absolute",
                    userSelect: "auto",
                    width: img.width.endsWith("px") ? img.width : `${img.width}px`,
                    height: img.width.endsWith("px") ? img.height : `${img.height}px`,
                    cursor: "move",
                    zIndex: 1,
                    transform: `translate(${img.x}px, ${img.y}px)`,
                  }}
                >
                  <img
                    className="w-100"
                    src={imageSrc}
                    alt={"Ranbazzar"}
                    style={{
                      position: "relative",
                      top: `${localStorage.getItem(`imageTopBottoms${index}`)}px`,
                      left: `${localStorage.getItem(`imageLeftRights${index}`)}px`,
                      transform: `rotate(${localStorage.getItem(`rotations${index}`) || 0}deg) 
                                  scale(${localStorage.getItem(`imageScales${index}`) || 1})`,
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                    onContextMenu={handleContextMenu}
                    loading="lazy"
                    width="300" // Explicit width
                    height="300" // Explicit height
                  />
                </div>
              );
            })}
          <div
            ref={containerImgRef}
            className="name-slip name-slip-bg"
            style={{
              background: `url(${bgCustomImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: customizeWidth,
              height: customizeHeight,
              overflow: "hidden",
              position: "relative",
              zIndex: 2,
              transition: "background 0.2s ease-in-out",
            }}
          >
            {/* Input Fields */}
            {!!inputFields &&
              inputFields.map((field, index) => {
                const maxLength = +field?.maxLength;
                const storedValue = localStorage.getItem(field?.label?.slug);
                let fieldValue = storedValue !== null ? storedValue : field.value;
                if (fieldValue.length > maxLength) {
                  let trimmedValue = fieldValue.substring(0, maxLength);
                  if (fieldValue[maxLength] !== " " && trimmedValue.lastIndexOf(" ") !== -1) {
                    trimmedValue = trimmedValue.substring(0, trimmedValue.lastIndexOf(" "));
                  }
                  fieldValue = trimmedValue;
                }
                const formattedName = capitalizeText(fieldValue);
                const fontSize = field?.style?.fontSize.endsWith("px")
                  ? field?.style?.fontSize
                  : `${field?.style?.fontSize}px`;
                return (
                  <div
                    key={index}
                    className="input react-draggable react-draggable-dragged"
                    style={{
                      position: "absolute",
                      userSelect: "auto",
                      color: field?.style?.color,
                      fontFamily: field?.style?.fontFamily,
                      fontWeight: field?.style?.fontWeight,
                      textAlign: field?.style?.textAlignment,
                      textTransform: field?.style?.textType,
                      transform: `rotate(${field?.style?.rotation || 0}deg)`,
                      fontSize: fontSize,
                      width: field?.width?.endsWith("px") ? field?.width : `${field?.width}px`,
                      top: `${field?.y}px`,
                      left: `${field?.x}px`,
                      cursor: "move",
                      zIndex: 3,
                    }}
                  >
                    {formattedName ? formattedName : fieldValue}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div style={{
          background: "#fff",
          width: customizeWidth,
          height: customizeHeight,
        }} />
      )}
    </Suspense>
  );
};

export default CustomizeProduct;
