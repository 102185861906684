import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userCartDetails } from "../../reducers/orderDetailsReducer";
import SideMenu from "../../Components/SideMenu";
import moment from "moment";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { userCartList } from "../../const";
import NumberPagination from "../../Pagination";
import DateSelection from "../DateSelection";

const UsersCartList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useRef(window.innerWidth);
  const queryParameters = new URLSearchParams(window.location.search);
  const pageCountner = queryParameters.get("page");
  const startDateUrl = queryParameters.get("startDate");
  const endDateUrl = queryParameters.get("endDate");
  const [page, setPage] = useState(pageCountner ? +pageCountner : 1);
  const [startDate, setStartDate] = useState(startDateUrl);
  const [endDate, setEndDate] = useState(endDateUrl);

  useEffect(() => {
    dispatch(userCartDetails({page, startDate, endDate}));
  }, []);
  const { userCartDetailsList, pageCounts } = useSelector(
    (state) => state.orderDetailReducer
  );
  const onPageChange = (number) => {
    setPage(number);
  };
  const handleDateRangeChange = ([startDate, endDate]) => {
    setStartDate(moment(startDate).format("YYYY/MM/DD"));
    setEndDate(moment(endDate).format("YYYY/MM/DD"));
  };
  const paginationFilter = {
    startDate,
    endDate
  }
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row>
          <Col md={4} className="mb-3">
          <DateSelection
                onDateRangeChange={handleDateRangeChange}
                api={userCartDetails}
              />
          </Col>
          <Col
            md={8}
            sx={8}
            xs={8}
            className={width.current > 500 ? "mb-4 text-end" : "mb-3"}
          >
            <Button
              variant="danger"
              // className="animatedd-clear-btn"
              onClick={() => {
                navigate(userCartList);
                window.location.reload()
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">User Detail</th>
              <th className="over-col-size">Phone</th>
              <th className="over-col-size">Quantity / Product</th>
            </tr>
          </thead>
          <tbody>
            {!!userCartDetailsList &&
              Object.values(userCartDetailsList)?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>
                      CustomerId: <b>{data?.[0]?.user?.username}</b>
                      <br />
                      <b>{`${data?.[0]?.user?.firstName} ${data?.[0]?.user?.lastName}`}</b>
                      <br />
                      Email: <b>{data?.[0]?.user?.email || ''}</b>
                      <br />
                      {`${data?.[0]?.user?.address?.[0]?.houseNo || ''} ${data?.[0]?.user?.address?.[0]?.addressline1 || ''}, ${data?.[0]?.user?.address?.[0]?.addressline2 || ''}`}<br/>
                      {`${data?.[0]?.user?.address?.[0]?.city || ''}-${data?.[0]?.user?.address?.[0]?.zipcode || ''}, ${data?.[0]?.user?.address?.[0]?.state || ''}`}
                    </td>
                    <td>{data?.[0]?.user?.phone}</td>
                    <td>
                      {!!data &&
                        data?.map((items, itemIndex) => {
                          return (
                            <Fragment key={itemIndex}>
                              <div className="user-cart-item mb-1 p-1"> 
                                <Row>
                                  <Col md={6}>
                                    <b>
                                    {moment(items?.createdAt).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                    </b>
                                    <br />
                                    Qty: <b>{items?.quantity}</b><br/>
                                    SKU: <b>{items?.product?.sku}</b><br/>
                                    Price: <b>{items?.product?.sale}/-</b><br/>
                                    <Link target="_blank" to={`/product/${items?.product?.slug}?productStatus=${items?.product?.status}`}>{items?.product?.name}</Link>
                                  </Col>
                                  <Col md={6}>
                                    <img alt={items?.product?.name} src={items?.mergeimg}/>
                                  </Col>
                                </Row>
                              </div>
                            </Fragment>
                          );
                        })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Row className="mt-3">
        <Col>
          <NumberPagination
            pageCount={pageCounts}
            currentPage={page}
            api={userCartDetails}
            onPageChange={onPageChange}
            pageurl={userCartList}
            filters={paginationFilter}
          />
        </Col>
      </Row>
      </div>
    </>
  );
};

export default UsersCartList;
