import React, { useEffect, useRef } from "react";
import { Link, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  about,
  home,
  contact,
  shop,
  openNewTab,
  handleContextMenu,
} from "../../const";
import { getPages, getSinglePage} from "../../reducers/frontEndReducer";
import youtube from "../../images/youtube.svg";
import bhim from "../../images/bhim-upi-v1.svg";
import facebook from "../../images/facebook.svg";
import gpay from "../../images/gpay-v1.svg";
import insta from "../../images/instagram.svg";
import mail from "../../images/mail.svg";
import mastercard from "../../images/mastercard-v1.svg";
import mastro from "../../images/mastro-v1.svg";
import paytm from "../../images/paytm-v1.svg";
import phone from "../../images/phone.svg";
import phonepay from "../../images/phone-pey-v1.svg";
import visa1 from "../../images/visa-electron-v2.svg";
import visa2 from "../../images/visa-v1.svg";
import whatsapp from "../../images/whatsapp.svg";
import quality from "../../images/premium-quality.svg";
import delivery from "../../images/fast-delivery.svg";
const Footer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const width = useRef(window.innerWidth);
  useEffect(() => {
    dispatch(getPages());
  }, []);
  const { getPagesList } = useSelector((state) => state.frontEndReducer);
  const { getSocialMediaSettingsData } = useSelector((state) => state.commonReducer);
  //clear local data
  const clearData = () => {
    // Save the necessary fields in a temporary object
    const savedData = {
      authToken: localStorage.getItem("x-auth-token"),
      slug: localStorage.getItem("slug"),
      userRole: localStorage.getItem("userRole"),
      username: localStorage.getItem("username"),
      phone: localStorage.getItem("phone"),
    };
    // Clear the local storage
    localStorage.clear();
    // Set back only the saved fields
    if (savedData.authToken) localStorage.setItem("x-auth-token", savedData.authToken);
    if (savedData.slug) localStorage.setItem("slug", savedData.slug);
    if (savedData.userRole) localStorage.setItem("userRole", savedData.userRole);
    if (savedData.username) localStorage.setItem("username", savedData.username);
    if (savedData.phone) localStorage.setItem("phone", savedData.phone);
    // Reload the page
    window.location.reload();
  };
  
  return (
    <>
      <footer
        className=" pt-4 pb-4 rounded-top"
        style={{ backgroundColor: "#012246",color:"white" }}
      >
        <div className="container">
          <div className="assurance-block w-md-80 mx-auto mb-4">
            <div className="hstack gap-4 gap-md-10 justify-content-around">
              <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                <img
                  onContextMenu={handleContextMenu}
                  src={quality}
                  alt="Premium Quality"
                  width="35"
                  height="35"
                  type="image/svg"
                />
                <p className="m-0 text-center text-white text-uppercase fs-9 fw-bold lh-sm">
                  Premium Quality <span className="d-block d-md-none"></span>
                  Assured
                </p>
              </div>

              <div className="vr bg-white "></div>

              <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                <img
                  onContextMenu={handleContextMenu}
                  src={delivery}
                  alt="Shipping"
                  width="35"
                  height="35"
        
                  type="image/svg"
                />
                <p className="m-0 text-center text-white text-uppercase fs-9 fw-bold lh-sm">
                  Free And Fast <span className="d-block d-md-none"></span>
                  Delivery
                </p>
              </div>

              <div className="vr bg-white "></div>

              <div className="d-flex flex-column align-items-center justify-content-center gap-2 ">
                <img
                  onContextMenu={handleContextMenu}
                  src={quality}
                  alt="return policy"
                  width="35"
                  height="35"
                
                  type="image/svg"
                />
                <p className="m-0 text-center text-white text-uppercase fs-9 fw-bold lh-sm">
                  Best Customer <span className="d-block d-md-none"></span>
                  Support
                </p>
              </div>
            </div>
          </div>

          <hr className="mb-2" />

          <div className="row text-center">
            <div className="col-md-6 mb-2">
              <p>FOLLOW US</p>
              <div className="d-flex justify-content-center">
                <span
                  onClick={() =>
                    openNewTab(getSocialMediaSettingsData?.facbook)
                  }
                  className="mx-2 mb-2"
                >
                  <img
                    onContextMenu={handleContextMenu}
                    src={facebook}
                    alt="Facebook"
                    loading="lazy"
                  />
                </span>
                {/* <span
                  onClick={() =>
                    openNewTab(getSocialMediaSettingsData?.twitter)
                  }
                  className="mx-2"
                >
                  <img
                    onContextMenu={handleContextMenu}
                    src={twitter}
                    alt="Twitter"
                  />
                </span> */}
                <span
                  onClick={() =>
                    openNewTab(getSocialMediaSettingsData?.instagram)
                  }
                  className="mx-2 mb-2"
                >
                  <img
                    onContextMenu={handleContextMenu}
                    src={insta}
                    alt="Instagram"
                    loading="lazy"
                  />
                </span>
                <span
                  onClick={() =>
                    openNewTab(getSocialMediaSettingsData?.youtube)
                  }
                  className="mx-2 mb-2"
                >
                  <img
                    onContextMenu={handleContextMenu}
                    src={youtube}
                    alt="YouTube"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <p>SUPPORT</p>
              <div className="d-flex justify-content-center">
                <Link
                  to={`tel:+91${getSocialMediaSettingsData?.phone}`}
                  className="mx-2"
                >
                  <img
                    onContextMenu={handleContextMenu}
                    src={phone}
                    alt="Phone"
                    loading="lazy"
                  />
                </Link>
                <Link
                  to={`mailto:${getSocialMediaSettingsData?.mail}`}
                  className="mx-2"
                >
                  <img
                    onContextMenu={handleContextMenu}
                    src={mail}
                    alt="Mail"
                    loading="lazy"
                  />
                </Link>
                <Link
                  target="_blank"
                  to={`https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi, I%27m%20interested%20in%20your%20services&source=&data=&app_absent=`}
                  className="mx-2"
                >
                  <img
                    onContextMenu={handleContextMenu}
                    src={whatsapp}
                    alt="WhatsApp"
                    loading="lazy"
                  />
                </Link>
                {/* <Link to="#" className="mx-2">
                <img onContextMenu={handleContextMenu} src={bot} alt="Bot" />
              </Link> */}
              </div>
            </div>
          </div>

          <hr className="mb-1" />

          <div className="row text-center">
            <div className="col-12 ">
              <p>100% SECURE PAYMENTS</p>
              <div className="d-flex justify-content-center">
                <Link to="#" className="mx-2" aria-label="Razorpay">
                  <img
                    onContextMenu={handleContextMenu}
                    src={visa2}
                    alt="Razorpay"
                    loading="lazy"
                  />
                </Link>
                <Link to="#" className="mx-2" aria-label="Paytm">
                  <img
                    onContextMenu={handleContextMenu}
                    src={paytm}
                    alt="Paytm"
                    loading="lazy"
                  />
                </Link>
                <Link to="#" className="mx-2" aria-label="UPI">
                  <img onContextMenu={handleContextMenu} src={bhim} alt="UPI" loading="lazy"/>
                </Link>
                <Link to="#" className="mx-2" aria-label="MasterCard">
                  <img
                    onContextMenu={handleContextMenu}
                    src={mastercard}
                    alt="Mastercard"
                    loading="lazy"
                  />
                </Link>
                <Link to="#" className="mx-2" aria-label="Visa">
                  <img
                    onContextMenu={handleContextMenu}
                    src={visa1}
                    alt="Visa"
                    loading="lazy"
                  />
                </Link>
                <Link to="#" className="mx-2" aria-label="Maestro">
                  <img
                    onContextMenu={handleContextMenu}
                    src={mastro}
                    alt="Maestro"
                    loading="lazy"
                  />
                </Link>
                <Link to="#" className="mx-2" aria-label="Phonepay">
                  <img
                    onContextMenu={handleContextMenu}
                    src={phonepay}
                    alt="phonepay"
                    loading="lazy"
                  />
                </Link>
                <Link to="#" className="mx-2" aria-label="gpay">
                  <img
                    onContextMenu={handleContextMenu}
                    src={gpay}
                    alt="gpay"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </div>

          <hr className="mb-3" />

          <div className="row bg-white rounded">
            <div className="col-md-5 yyyy">
              <div className="d-md-none">
                <button
                  className="btn btn-outline-light w-100 mb-2 text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#companyCollapse"
                  aria-expanded="false"
                  aria-controls="companyCollapse"
                >
                  <b>COMPANY </b>
                </button>
              </div>
              <div className="collapse d-md-block" id="companyCollapse">
                <div className="bg-white text-dark font-weight-bold py-2">
                  <h3>
                    <u>Company</u>
                  </h3>
                  <p>
                    <Link to={home} className="text-dark">
                      Home
                    </Link>
                  </p>
                  <p>
                    <Link to={shop} className="text-dark">
                      Shop
                    </Link>
                  </p>
                  <p>
                    <Link to={about} className="text-dark">
                      About Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <div className="d-md-none">
                <button
                  className="btn btn-outline-light w-100 mb-2 text-dark "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#bestSellersCollapse"
                  aria-expanded="false"
                  aria-controls="bestSellersCollapse"
                >
                  <b>POLICY</b>
                </button>
              </div>
              <div className="collapse d-md-block" id="bestSellersCollapse">
                <div className="bg-white text-dark font-weight-bold py-2">
                  <h3>
                    <u>POLICY</u>
                  </h3>
                  {
                    !!getPagesList && getPagesList?.map((data, index) => {
                      return(
                        <p key={index}>
                          <Link
                            onClick={() => {
                              dispatch(
                                getSinglePage({ slug: data?.slug })
                              );
                            }}
                            to={`/${data?.slug}`}
                            className="text-dark"
                          >
                            {data?.name}
                          </Link>
                        </p>
                      )
                    })
                  }
                </div>
              </div> 
              {!(location.pathname.includes('checkout') || location.pathname.includes('user-address')) && <button className="clear-btn text-dark border-dark" onClick={clearData}>
                Clear
              </button>}
              {
                <p className="fixed-whatsapp">
                  <span
                    onClick={() =>
                      openNewTab(
                        `https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi. I am intrested in Your Services..&source=&data=&app_absent=`
                      )
                    }
                  >
                    <i className="bi bi-whatsapp"></i>
                  </span>
                </p>
              }
            </div>
            

            <div className="col-md-1">
              <div className="d-md-none">
                <button
                  className="btn btn-outline-light w-100 mb-2 text-dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#supportCollapse"
                  aria-expanded="false"
                  aria-controls="supportCollapse"
                >
                  <b>SUPPORT</b>
                </button>
              </div>
              <div className="collapse  d-md-block" id="supportCollapse">
                <div className="bg-white text-dark font-weight-bold py-2">
                  <h3>
                    <u>Support</u>
                  </h3>
                  <p>
                    <Link to={contact} className="text-dark">
                      Contact Us
                    </Link>
                  </p>
                  {/* <p>
                    <Link to="#" className="text-dark">
                      FAQ's
                    </Link>
                  </p> */}
                </div>
              </div>
            </div>

            
          </div>
          <hr className="mb-4" />
          <div className="row ">
            <div className="col-md-12 text-center ">
              <p className="text-white mb-0">
                &copy; {new Date().getFullYear()} All rights reserved by{" "}
                <Link to="#" className="text-white">
                  Ranbazaar
                </Link>
              </p>
              
            </div>
          </div>
        </div>
      </footer>

      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
