import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { couponVerify } from "../../../reducers/commonReducer";

const schema = yup.object().shape({
  code: yup.string().required(),
});

const Coupon = ({couponAmount, popupClose, orderAmountValue}) => {
  const dispatch = useDispatch();
  return (
    <>
              <Formik
                validationSchema={schema}
                onSubmit={async (values, { resetForm }) => {
                  const apiResp = await dispatch(couponVerify(values));
                  if(apiResp){
                    localStorage.setItem("code", values?.code);
                    localStorage.setItem("couponoffer", true);
                    resetForm({ values: "" });
                    localStorage.setItem("couponapplyName", `${apiResp?.payload?.offer?.rate} ${apiResp?.payload?.offer?.discountType === 1 ? `₹` : `%`} discount`)
                    couponAmount(apiResp?.payload?.offer?.rate, false, (apiResp?.payload?.disc ? apiResp?.payload?.disc : (apiResp?.payload?.offer?.discountType === 1 ? apiResp?.payload?.offer?.rate : (orderAmountValue * apiResp?.payload?.offer?.rate / 100))) );
                    popupClose();
                  }
                }}
                initialValues={{
                    code: "",
                }}
              >
                {({ handleSubmit, handleChange, touched, values, errors }) => (
                  <Form className="container" onSubmit={handleSubmit}>
                    <Row>
                      <Col md={8} sm={8} xs={8} className="p-0">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Please Enter Coupon"
                            name="code"
                            value={values.code}
                            onChange={handleChange}
                            isInvalid={!!errors.code}
                            className={(errors.code && touched.code) ? "errors" : "border-success"}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.code}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={4} xs={4} className="p-0">
                        <Button
                          variant="success"
                          className="bg-thememain w-100 ml-2"
                          type="submit"
                          style={{backgroundColor: 'var(--secondColor)'}}
                        >
                          Verify
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
    </>
  );
};

export default Coupon;
