import React, { useEffect, useState } from "react";
import CommonHeader from "../../Sub/CommonHeader";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Sub/Footer";
import { useNavigate } from "react-router-dom";
import {
  userAddress,
  zipcodeCityState,
} from "../../../reducers/commonReducer";

// const schema = yup.object().shape({
//   firstName : yup.string().required(),
//   // phone: yup.number().required(),
//   phone: yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").min(10).max(10).required(),
//   addressline1: yup.string().required(),
//   addressline2: yup.string(),
//   city: yup.string().required(),
//   zipcode: yup
//     .string()
//     .required()
//     .max(6, "Enter valid PIN Code.")
//     .min(6, "Enter your 6 digit PIN code."),
//   state: yup.string().required(),
// });

const AddUserAddress = ({onSendData, billingShippingStatus, btntext, houseNo, addressIndex, firstName, lastName, addressline1, addressline2, city, zipcode, state, phone, email}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addressState, setAddressState] = useState();
  const [addressCity, setAddressCity] = useState();
  const queryParameters = new URLSearchParams(window.location.search);
  const amount = queryParameters.get("amount");
  const { zipcodeCityStateData } = useSelector(
    (state) => state.commonReducer
  );
  const isZipcodeCityStateAvailable = !!zipcodeCityStateData?.Pincode;
  useEffect(() =>{
    setAddressCity(zipcodeCityStateData?.City);
    setAddressState(zipcodeCityStateData?.State)
  },[zipcodeCityStateData])

  const getValidationSchema = () => {
    if (!addressCity && !addressState) {
      return yup.object().shape({
        firstName: yup.string().required("First Name is required").matches(/^[^\s].*$/, 'First name cannot start with a space'),
        phone: yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").length(10, "Phone number must be exactly 10 digits").required("Phone number is required"),
        alternateNumber: yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").length(10, "Phone number must be exactly 10 digits"),
        addressline1: yup.string().required("Address Line 1 is required").matches(/^[^\s].*$/, 'Addressline1 cannot start with a space'),
        addressline2: yup.string(),
        zipcode: yup.string().required("ZIP Code is required").length(6, "Enter a valid 6-digit PIN Code."),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        houseNo: yup
        .string()
        .required("House No is required")
        .matches(/^[^\s][a-zA-Z0-9 ]*$/, "House No cannot start with a space and should only contain letters, numbers, and spaces"),      
        email: yup
        .string()
        .required("Email is required")
        .matches(/^[^\s].*$/, "Email cannot start with a space")
        .email("Enter a valid email address"),      
      });
    }
    // Return the default schema or another schema as needed
    return yup.object().shape({
      firstName: yup.string().required("First Name is required").matches(/^[^\s].*$/, 'First name cannot start with a space'),
      phone: yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").length(10, "Phone number must be exactly 10 digits").required("Phone number is required"),
      alternateNumber: yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").length(10, "Phone number must be exactly 10 digits"),
      addressline1: yup.string().required("Address Line 1 is required").matches(/^[^\s].*$/, 'Addressline1 cannot start with a space'),
      addressline2: yup.string(),
      zipcode: yup.string().required("ZIP Code is required").length(6, "Enter a valid 6-digit PIN Code."),
      city: yup.string().optional(),
      state: yup.string().optional(),
      houseNo: yup
      .string()
      .required("House No is required")
      .matches(/^[^\s][a-zA-Z0-9 ]*$/, "House No cannot start with a space and should only contain letters, numbers, and spaces"),      
      email: yup.string()
  .required("Email is required")
  .matches(/^[^\s].*$/, "Email cannot start with a space")
  .email("Enter a valid email address"),
    });
  };
  return (
    <>
      {!billingShippingStatus && <CommonHeader />}
      <div className="container-fluid">
        <div className="page-main p-5 mbl-pedding-10">
          <div className="section__head d-md-flex justify-content-between mb-40">
            <div className="section__title">
              <h1>Add Address🏠🏠</h1>
            </div>
          </div>
          <Row className="align-item-center">
            <Col md={12} className="box-shadows mbl-pedding-10 p-5">
              <div className="border-y mb-4">
                <h2 className="mb-0">Fill Your Address</h2>
              </div>
              <Formik
                validationSchema={getValidationSchema()}
                onSubmit={async (values, { resetForm }) => {
                  const apiResp = await dispatch(userAddress({
                    ...values,
                    city: addressCity || values.city, 
                    zipcode: zipcodeCityStateData?.Pincode || values.zipcode, 
                    state: addressState || values.state, 
                  }));
                  if(apiResp?.payload?.status){
                    navigate(`/user-address?amount=${amount}&addNew=${true}`);
                    resetForm({ values: "" });
                    if(billingShippingStatus){
                      onSendData(apiResp?.payload)
                      localStorage.setItem("billingFullAdrs", JSON.stringify(apiResp?.payload?.address))
                      billingShippingStatus && localStorage.setItem("billingAdrs", apiResp?.payload?.status)
                    }
                  }
                }}
                initialValues={{
                  firstName, lastName, addressline1, addressline2, city : !!addressCity ? addressCity : city, zipcode: !!zipcodeCityStateData?.Pincode
                   ? zipcodeCityStateData?.Pincode : zipcode, state : !!addressState ? addressState : state, phone: localStorage.getItem("phone"), email,
                   gst:'', organisationName:'', alternateNumber : '', billing : billingShippingStatus ? true : false, houseNo
                  // firstName: "",
                  // lastName: "",
                  // email: "",
                  // phone: localStorage.getItem("phone"),
                  // addressline1: "",
                  // addressline2: "",
                  // zipcode: !!zipcodeCityStateData?.Pincode
                  //   ? zipcodeCityStateData?.Pincode
                  //   : localStorage.getItem("pincode"),
                  // city: !!zipcodeCityStateData?.City
                  //   ? zipcodeCityStateData?.City
                  //   : localStorage.getItem("city"),
                  // state: !!zipcodeCityStateData?.State
                  //   ? zipcodeCityStateData?.State
                  //   : localStorage.getItem("state"),
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  touched,
                  values,
                  errors,
                }) => (
                  <Form className="container" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>FirstName</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="FirstName"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                            className={
                              errors.firstName && touched.firstName && "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>LastName</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="LastName"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                            className={
                              errors.lastName && touched.lastName && "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Phone"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                            className={
                              errors.phone && touched.phone && "errors"
                            }
                            disabled={true}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>Alternate Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Alternate Phone"
                            name="alternateNumber"
                            value={values.alternateNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.alternateNumber}
                            className={
                              errors.alternateNumber && touched.alternateNumber && "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.alternateNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                    <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            className={
                              errors.email && touched.email && "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>PIN Code</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="PIN Code"
                            name="zipcode"
                            value={values.zipcode}
                            onChange={async (e) => {
                              setFieldValue("zipcode", e.target.value);
                              e.target.value?.length === 6 &&
                                dispatch(
                                  zipcodeCityState({
                                    zipcode: e.target.value,
                                  })
                                );
                            }}
                            isInvalid={!!errors.zipcode}
                            className={
                              errors.zipcode && touched.zipcode && "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.zipcode}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            name="city"
                            value={addressCity ? addressCity : values.city}
                            onChange={handleChange}
                            isInvalid={!addressCity && !!errors.city}
                            className={!addressCity && (errors.city && touched.city && "errors")}
                            disabled
                          />
                         {!addressCity && <Form.Control.Feedback type="invalid">
                            {errors.city}
                          </Form.Control.Feedback>}
                        </Form.Group>
                      </Col>
                      <Col md={3} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="State"
                            name="state"
                            value={addressState ? addressState : values.state}
                            onChange={handleChange}
                            isInvalid={!addressState && !!errors.state}
                            className={
                              !addressState && (errors.state && touched.state && "errors")
                            }
                            disabled
                          />
                          {!addressState && <Form.Control.Feedback type="invalid">
                            {errors.state}
                          </Form.Control.Feedback>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4} sm={12} xs={12}>
                        <Form.Group>
                          <Form.Label>House No/Flat No/Area</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="House No/Flat No/Area"
                            name="houseNo"
                            value={values.houseNo}
                            onChange={handleChange}
                            isInvalid={!!errors.houseNo}
                            className={
                              errors.houseNo && touched.houseNo && "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.houseNo}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>Addressline 1 </Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea"
                            row={3}
                            placeholder="Addressline 1 "
                            name="addressline1"
                            value={values.addressline1}
                            onChange={handleChange}
                            isInvalid={!!errors.addressline1}
                            className={
                              errors.addressline1 &&
                              touched.addressline1 &&
                              "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.addressline1}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={12} xs={6}>
                        <Form.Group>
                          <Form.Label>Addressline 2</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea"
                            row={3}
                            placeholder="Addressline 2"
                            name="addressline2"
                            value={values.addressline2}
                            onChange={handleChange}
                            isInvalid={!!errors.addressline2}
                            className={
                              errors.addressline2 &&
                              touched.addressline2 &&
                              "errors"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.addressline2}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    {
                      billingShippingStatus && <Row className="mb-3">
                      <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>Organisation Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Organisation Name"
                            name="organisationName"
                            value={values.organisationName}
                            onChange={handleChange}
                            isInvalid={!!errors.organisationName}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>GST</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter GST"
                            name="gst"
                            value={values.gst}
                            onChange={handleChange}
                            isInvalid={!!errors.gst}
                          />
                        </Form.Group>
                      </Col>
                      </Row>
                    }
                    <Button
                      variant="success"
                      className="bg-thememain w-100"
                      type="submit"
                    >
                      {btntext ? btntext : `Submit` }
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </div>
      {!billingShippingStatus && <Footer />}
    </>
  );
};

export default AddUserAddress;

