import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const TagingInput = ({ onTagsChange, values }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(Array.isArray(values) ? values : []);
  }, [values]);

  const [input, setInput] = useState("");
  const handleKeyPress = (e) => {
    if (e.key === ",") {
      const newTag = input.trim();
      if (newTag) {
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        onTagsChange(updatedTags); 
      }
      setInput("");
      e.preventDefault(); 
    }
  };

  const removeTag = (indexToRemove) => {
    const updatedTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(updatedTags);
    onTagsChange(updatedTags); 
  };

  return (
    <div>
      <div className="tag-container">
        {tags?.map((tag, index) => (
          <span key={index} className="tag mx-2">
            <b>{tag.length > 0 && tag}</b>{" "}
            <button
              className="bg-danger px-1"
              onClick={() => removeTag(index)}
            >
              <i className="text-white bi bi-trash3-fill"></i>
            </button>
          </span>
        ))}
      </div>
      <Form.Control
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Type and press ',' to add tag"
      />
    </div>
  );
};

export default TagingInput;
