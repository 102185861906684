import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAddressDetails,
  updateUserAddress,
  zipcodeCityState,
  orderAddressUpdate,
} from "../../../reducers/commonReducer";
import { singleOrder } from "../../../reducers/orderDetailsReducer";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[^\s].*$/, "First name cannot start with a space"),
  lastName: yup.string(),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only numbers")
    .min(10)
    .max(10)
    .required(),
  alternateNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only numbers")
    .min(10)
    .max(10),
  addressline1: yup
    .string()
    .required()
    .matches(/^[^\s].*$/, "Addressline1 cannot start with a space"),
  addressline2: yup.string(),
  city: yup
    .string()
    .required()
    .matches(/^[^\s].*$/, "City cannot start with a space"),
  zipcode: yup.string().min(6).max(6).required(),
  state: yup
    .string()
    .required()
    .matches(/^[^\s].*$/, "State cannot start with a space"),
  houseNo: yup
    .string()
    .required("House No is required")
    .matches(
      /^[^\s][a-zA-Z0-9 ]*$/,
      "House No cannot start with a space and should only contain letters, numbers, and spaces"
    ),
  email: yup
    .string()
    .required("Email is required")
    .matches(/^[^\s].*$/, "Email cannot start with a space")
    .email("Enter a valid email address"),
});

function UpdateAddress(props) {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const {
    title,
    firstName,
    lastName,
    addressline1,
    addressline2,
    id,
    zipcode,
    alternateNumber,
    phone,
    email,
    billing,
    indexId,
    shippingAddress,
    houseNo,
    userid,
    city,
    state,
    organisationName,
    gst,
  } = props;
  const [addressState, setAddressState] = useState(city);
  const [addressCity, setAddressCity] = useState(state);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    if (indexId === 0) {
      houseNo && email ? setShow(false) : setShow(true);
    }
  }, [indexId]);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
    dispatch(
      zipcodeCityState({
        zipcode: zipcode,
      })
    );
  };
  const { zipcodeCityStateData } = useSelector((state) => state.commonReducer);
    useEffect(() =>{
      setAddressCity(zipcodeCityStateData?.City);
      setAddressState(zipcodeCityStateData?.State)
    },[zipcodeCityStateData])
  return (
    <>
      <Button
        variant="success"
        className="rounded py-0 px-1"
        onClick={handleShow}
      >
        Edit
      </Button>
      <Modal
        show={show}
        // onHide={handleClose}
      >
        <Modal.Header
        // closeButton
        >
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={id ? false : true}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = orderId
              ? await dispatch(
                  orderAddressUpdate({ ...values, shippingAddress, city: addressCity || values.city, 
                    zipcode: zipcodeCityStateData?.Pincode || values.zipcode, 
                    state: addressState || values.state })
                )
              : await dispatch(updateUserAddress({...values, 
                city: addressCity || values.city, 
                zipcode: zipcodeCityStateData?.Pincode || values.zipcode, 
                state: addressState || values.state, }));
            if (apiResp?.payload?.status) {
              orderId
                ? dispatch(singleOrder({ id: orderId }))
                : dispatch(getUserAddressDetails());
              handleClose();
            }
            resetForm({ values: "" });
          }}
          initialValues={{
            firstName,
            lastName,
            alternateNumber,
            addressline1,
            addressline2: addressline2 === null ? "" : addressline2,
            city: id ? city : addressCity,
            zipcode,
            state: id ? state : addressState,
            phone,
            email,
            index: indexId,
            id,
            userid,
            organisationName,
            gst,
            houseNo,
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, touched, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FirstName"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>LastName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>House No/Flat No/Area</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="House No/Flat No/Area"
                      name="houseNo"
                      value={values.houseNo}
                      onChange={handleChange}
                      isInvalid={!!errors.houseNo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.houseNo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>AddressLine1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="AddressLine1"
                      name="addressline1"
                      value={values.addressline1}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>AddressLine2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="AddressLine2"
                      name="addressline2"
                      value={values.addressline2}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Alternate Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Alternate Number"
                      name="alternateNumber"
                      value={values.alternateNumber}
                      onChange={handleChange}
                      isInvalid={!!errors.alternateNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.alternateNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>PIN Code</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="PIN Code"
                      name="zipcode"
                      value={values.zipcode}
                      onChange={async (e) => {
                        setFieldValue("zipcode", e.target.value);
                        if (e.target.value?.length === 6) {
                          const apiRes = await dispatch(
                            zipcodeCityState({
                              zipcode: e.target.value,
                            })
                          );
                        }
                      }}
                      isInvalid={!!errors.zipcode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zipcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={addressCity ? addressCity : values.city}
                      onChange={handleChange}
                      isInvalid={!addressCity && !!errors.city}
                      className={
                        !addressCity && errors.city && touched.city && "errors"
                      }
                      disabled
                    />
                    {!addressCity && (
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                      disabled = {id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group> */}
                </Col>
                <Col md={6} sm={6} xs={6}>
                 <Form.Group>
                                          <Form.Label>State</Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="State"
                                            name="state"
                                            value={addressState ? addressState : values.state}
                                            onChange={handleChange}
                                            isInvalid={!addressState && !!errors.state}
                                            className={
                                              !addressState && (errors.state && touched.state && "errors")
                                            }
                                            disabled
                                          />
                                          {!addressState && <Form.Control.Feedback type="invalid">
                                            {errors.state}
                                          </Form.Control.Feedback>}
                                        </Form.Group>
                  {/* <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                      disabled={id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group> */}
                </Col>
              </Row>
              {billing && (
                <Row className="mb-3">
                  <Col md={6} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label>GST</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="GST"
                        name="gst"
                        value={values.gst}
                        onChange={handleChange}
                        isInvalid={!!errors.gst}
                        disabled={id ? false : true}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.gst}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="OrganisationName"
                        name="organisationName"
                        value={values.organisationName}
                        onChange={handleChange}
                        isInvalid={!!errors.organisationName}
                        disabled={id ? false : true}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.organisationName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                {!!houseNo && (
                  <Button variant="danger" onClick={handleClose}>
                    Close
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UpdateAddress;
